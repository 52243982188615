import {useQuery} from "@apollo/client";
import {GET_METERS_DATA_QUERY} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param variables
 * @param onCompleted
 * @param onError
 */
export function useMetersData(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(GET_METERS_DATA_QUERY, {
    variables,
    onCompleted: ({metersData}) => {
      onCompleted(metersData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });
  const convertedData = [];
  if (data?.metersData?.data) {
    const dataArray = data?.metersData?.data;
    Array.prototype.push.apply(convertedData, dataArray);
  }
  // let sumRow = [];
  //
  // if (data?.metersData?.data) {
  //   convertedData = data?.metersData?.data.map(obj => ({
  //     ...obj, id: Math.random()
  //   }));
  // }

  // if (data?.metersData?.total?.consumptionV) {
  //   const totalData = data?.metersData?.total;
  //   const sumRow = {
  //     "id": "summary",
  //     "ts": "Summary",
  //     "label": "Summary",
  //     "consumptionConversionRate": 0,
  //     "productionConversionRate": 0,
  //     "consumptionCost": totalData.consumptionCost || 0,
  //     "productionCost": 0,
  //     "consumptionV": totalData.consumptionV || 0,
  //     "productionV": 0,
  //     "consumptionCo2": 0,
  //     "productionCo2": 0,
  //     "vPerM2": 0,
  //     "co2PerM2": 0,
  //     "productionPrice": 0,
  //     "consumptionPrice": totalData.consumptionWeightedAvgPrice,
  //     "productionAggregatedTax": 0,
  //     "consumptionAggregatedTax": totalData.consumptionWeightedAvgAggregatedTax
  //   };
  //
  //   convertedData.push(sumRow);
  // }

  return {
    isElectricityGreen: data?.metersData?.electricityGreen || false,
    data: convertedData,
    loading
  };
}
