import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext, useUser} from "../../providers";
import PropTypes from "prop-types";
import {useCompanies, useGetUserCompanies, useHasPermission, useUpdateProfile} from "../../../hooks";
import {FabekeDropdown} from "./FabekeDropdown";
import {SimpleDropdown} from "./SimpleDropdown";
import {AutocompleteDropdown} from "./AutocompleteDropdown";

const codesAllCompanies = ["all-companies-full", "all-Companies-readonly", "everything-full", "everything-readonly", "developer"];

/**
 * Customer dropdown component
 * @param {object} props props
 * @returns {React.JSX.Element} React Component
 * @class
 */
export const CustomerDropdownCommon = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const {updateProfile} = useUpdateProfile();
  const {
    currentTeamId,
    currentTeam
  } = user || {
  };
  const {companiesList} = useGetUserCompanies();
  const {customers} = useCompanies();
  const allowAllCompanies = useHasPermission(codesAllCompanies);

  const propsDefault = {
    defaultValue: currentTeamId,
    showAll: false,
    componentLabel: ""
  };

  //region UseState [React Hooks]
  const [customerValue, setCustomerValue] = useState(props.defaultValue ? props.defaultValue : propsDefault.defaultValue);
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showAll, setShowAll] = useState(props.showAll ? props.showAll : propsDefault.showAll);
  //endregion UseState

  //region UseEffect [React Hooks]
  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (!customerValue && selectedCompanies.length) {
      updateProfile({
        currentTeam: selectedCompanies[0].name,
        currentTeamId: selectedCompanies[0].id
      });
    }
  }, [customerValue, selectedCompanies, updateProfile]);

  useEffect(() => {
    if (allowAllCompanies) {
      if (customers.length > 0) {
        setSelectedCompanies(customers);
      }
    } else {
      if (companiesList.length > 0) {
        setSelectedCompanies(companiesList);
      }
    }

  }, [allowAllCompanies, companiesList, customers]);

  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      setCustomerValue(propsDefault.defaultValue);
    } else {
      setCustomerValue(props.defaultValue);
    }
  }, [props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    const customerArray = [];
    let selectedCustomerId = "";
    if (showAll) {
      customerArray.push({
        label: gettext("All"),
        value: "all"
      });
    }
    selectedCompanies.map(customerRow => {
      if (customerRow.name === currentTeam) {
        selectedCustomerId = customerRow.id;
      }
      const oneCustomer = {
        label: customerRow.name,
        value: customerRow.id
      };
      customerArray.push(oneCustomer);
      return true;
    });
    setCompanies(customerArray);
    if (propsDefault.defaultValue) {
      setCustomerValue(propsDefault.defaultValue);
    } else {
      setCustomerValue(selectedCustomerId);
    }
    // eslint -disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies, currentTeam, propsDefault.defaultValue, showAll, gettext]);

  //endregion useEffect
  const handleChange = event => {
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };
  //endregion Handlers

  // useEffect(() => {
  //   // Calculate the maximum width of options
  //   const maxWidth = Math.max(...companies.map(option => option.label.length));
  //
  //   // Set a dynamic width based on the maximum label length
  //   const dynamicWidth = maxWidth * 5 + 50; // Adjust the multiplier and offset as needed
  //
  //   // Apply the dynamic width to the Autocomplete element
  //   const autocompleteElement = document.getElementById('size-small-outlined');
  //   autocompleteElement.style.width = `${dynamicWidth}px`;
  // }, [companies]);

  return (
    <>
      {/*<Autocomplete*/}
      {/*  id="size-small-outlined"*/}
      {/*  size="small"*/}
      {/*  options={companies}*/}
      {/*  // getOptionLabel={(option) => option.label}*/}
      {/*  // getOptionKey={(option) => option.value}*/}
      {/*  // defaultValue={companies.find(option => option.value === customerValue) || null}*/}
      {/*  value={companies.find(option => option.value === customerValue) || null}*/}
      {/*  disableClearable*/}
      {/*  onChange={handleChange}*/}
      {/*  renderInput={(params) => (*/}
      {/*    <TextField {...params} label="Companies" placeholder="Companies"/>*/}
      {/*  )}*/}
      {/*/>*/}

      {props.visualStyle === "autoComplete" && (
        <AutocompleteDropdown
          options={companies}
          componentLabel={componentLabel}
          defaultValue={customerValue}
          onChange={handleChange}
          size="small"
        />
      )}

      {props.visualStyle === "simple" && (
        <SimpleDropdown
          options={companies}
          componentLabel={componentLabel}
          defaultValue={customerValue}
          onChange={handleChange}
          size="small"
        />
      )}

      {props.visualStyle === "old" && (
        <FabekeDropdown
          options={companies}
          componentLabel={componentLabel}
          defaultValue={customerValue}
          onChange={handleChange}
        />
      )}
    </>
  );

};

CustomerDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  visualStyle: PropTypes.oneOf(["simple", "old", "autoComplete"]).isRequired
};
