import React, {useCallback, useContext, useEffect, useState} from "react";
import {useTextDomainContext, useUser} from "app/providers";
import {useAddDeal, useDealById, useEditDeal} from "../../../hooks";

import {Box, Button, Grid, Paper, Stack, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {BuildingDropdownSimple,
  CurrencyDropdownSimple,
  EnergyTypeDropdownSimple,
  FilterBox,
  MeterDropdownSimple,
  PriceTypeDropdownSimple,
  SingleDatePicker,
  TableBox} from "../../components";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {formatDateForAPI, sendSlackMessage} from "../../../utils";

/**
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AddDeal = (props) => {
  const navigate = useNavigate();
  let editableRow = {
  };
  const {enqueueSnackbar} = useSnackbar();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const localFilters = JSON.parse(localStorage.getItem("addDealCache"));

  //
  const {user} = useUser();
  // const { currentTeam, countryCode: clientCountry, timezone } = user || {
  // };
  const {
    // currentTeam,
    currentSubsidiary,
    countryCode: clientCountry
  } = user || {
  };
  // const timeNow = useTimezoneNow(timezone);
  // const currentDate = new Date(timeNow);
  const currentDate = new Date();
  const {id} = useParams();
  const {dealRow} = useDealById(id ? id : "");

  if (id && dealRow?.building) {
    editableRow = dealRow;
  }

  const defaultValues = {
    "building": localFilters?.buildingValue ? localFilters.buildingValue : "all",
    "meter": localFilters?.meterValue ? localFilters.meterValue : "all",
    "energyType": localFilters?.energyTypeValue ? localFilters.energyTypeValue : "electricity",
    "fromDate": new Date(currentDate.getFullYear(), 0, 1),
    "toDate": new Date(currentDate.getFullYear(), 11, 31),
    "currency": "dkk",
    "mainPriceType": "fixed-price",

    "priceType": "fixed",
    "pricePrice": 0,
    "priceKwh": 0,
    "priceShare": 100,

    "systemType": "variable",
    "systemPrice": 0,
    "systemKwh": 0,
    "systemShare": 100,

    "epadType": "variable",
    "epadPrice": 0,
    "epadKwh": 0,
    "epadShare": 100,

    "profileType": "variable",
    "profilePrice": 0,
    "profileKwh": 0,
    "profileShare": 100,

    "markupPrice": 0,
    "markupShare": 100,

    "commissionPrice": 0,
    "commissionShare": 100,

    "priceColumn": gettext("Price (øre/kWh)")
  };
  const selectedValues = {
    ...defaultValues, ...editableRow
  };

  //region Form default values [React Hooks]
  const [buildingValue, setBuildingValue] = useState(selectedValues.building);
  const [meterValue, setMeterValue] = useState(selectedValues.meter);
  const [energyTypeValue, setEnergyTypeValue] = useState(selectedValues.energyType);
  const [fromDateValue, setFromDateValue] = useState(selectedValues.fromDate);
  const [toDateValue, setToDateValue] = useState(selectedValues.toDate);
  const [currencyValue, setCurrencyValue] = useState(selectedValues.currency);
  const [mainPriceTypeValue, setMainPriceTypeValue] = useState(selectedValues.mainPriceType);

  const [priceTypeValue, setPriceTypeValue] = useState(selectedValues.priceType);
  const [pricePriceValue, setPricePriceValue] = useState(selectedValues.pricePrice);
  const [priceKwhValue, setPriceKwhValue] = useState(selectedValues.priceKwh);
  const [priceShareValue, setPriceShareValue] = useState(selectedValues.priceShare);

  const [systemTypeValue, setSystemTypeValue] = useState(selectedValues.systemType);
  const [systemPriceValue, setSystemPriceValue] = useState(selectedValues.systemPrice);
  const [systemKwhValue, setSystemKwhValue] = useState(selectedValues.systemKwh);
  const [systemShareValue, setSystemShareValue] = useState(selectedValues.systemShare);

  const [epadTypeValue, setEpadTypeValue] = useState(selectedValues.epadType);
  const [epadPriceValue, setEpadPriceValue] = useState(selectedValues.epadPrice);
  const [epadKwhValue, setEpadKwhValue] = useState(selectedValues.epadKwh);
  const [epadShareValue, setEpadShareValue] = useState(selectedValues.epadShare);

  const [profileTypeValue, setProfileTypeValue] = useState(selectedValues.profileType);
  const [profilePriceValue, setProfilePriceValue] = useState(selectedValues.profilePrice);
  const [profileKwhValue, setProfileKwhValue] = useState(selectedValues.profileKwh);
  const [profileShareValue, setProfileShareValue] = useState(selectedValues.profileShare);

  const [markupPriceValue, setMarkupPriceValue] = useState(selectedValues.markupPrice);
  const [markupShareValue, setMarkupShareValue] = useState(selectedValues.markupShare);

  const [commissionPriceValue, setCommissionPriceValue] = useState(selectedValues.commissionPrice);
  const [commissionShareValue, setCommissionShareValue] = useState(selectedValues.commissionShare);

  const [priceColumnName, setPriceColumnName] = useState(selectedValues.priceColumn);
  const [countryCode, setCountryCode] = useState("DK");

  const [showPrice, setShowPrice] = useState(false);
  const [showSystem, setShowSystem] = useState(false);
  const [showEpad, setShowEpad] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const [showMarkup, setShowMarkup] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  //endregion default values

  //region userEffects [React Hooks]
  useEffect(() => {
    setCountryCode(clientCountry);
  }, [clientCountry]);

  // useEffect(() => {
  //   setShowSystem(false);
  //   setShowEpad(false);
  //   setShowProfile(false);
  //   setShowPrice(false);
  //   switch (countryCode) {
  //   case "DE":
  //     setShowProfile(true);
  //     setShowPrice(true);
  //     break;
  //   default:
  //     setShowSystem(true);
  //     setShowEpad(true);
  //     setShowProfile(true);
  //     break;
  //   }
  // }, [countryCode]);

  useEffect(() => {
    localStorage.setItem(
      "addDealCache",
      JSON.stringify({
        buildingValue: buildingValue,
        meterValue: meterValue,
        energyTypeValue: energyTypeValue,
        fromDateValue: fromDateValue,
        toDateValue: toDateValue,
        currencyValue: currencyValue,
        mainPriceTypeValue: mainPriceTypeValue
      })
    );
  }, [meterValue, buildingValue, energyTypeValue, fromDateValue, toDateValue, currencyValue, mainPriceTypeValue]);

  useEffect(() => {
    if (editableRow.building) {
      setBuildingValue(editableRow.building);
    }
  }, [editableRow.building]);

  useEffect(() => {
    if (editableRow.meter) {
      setMeterValue(editableRow.meter);
    }
  }, [editableRow.meter]);

  useEffect(() => {
    if (editableRow.energyType) {
      setEnergyTypeValue(editableRow.energyType);
    }
  }, [editableRow.energyType]);

  useEffect(() => {
    if (editableRow.fromDate) {
      setFromDateValue(new Date(editableRow.fromDate));
    }
  }, [editableRow.fromDate]);

  useEffect(() => {
    if (editableRow.toDate) {
      setToDateValue(new Date(editableRow.toDate));
    }
  }, [editableRow.toDate]);

  useEffect(() => {
    if (editableRow.currency) {
      setCurrencyValue(editableRow.currency);
    }
  }, [editableRow.currency]);

  useEffect(() => {
    if (editableRow.mainPriceType) {
      setMainPriceTypeValue(editableRow.mainPriceType);
    }
  }, [editableRow.mainPriceType]);

  useEffect(() => {
    if (editableRow.priceType) {
      setPriceTypeValue(editableRow.priceType);
    }
  }, [editableRow.priceType]);

  useEffect(() => {
    if (editableRow.pricePrice) {
      setPricePriceValue(editableRow.pricePrice);
    }
  }, [editableRow.pricePrice]);

  useEffect(() => {
    if (editableRow.priceKwh) {
      setPriceKwhValue(editableRow.priceKwh);
    }
  }, [editableRow.priceKwh]);

  useEffect(() => {
    if (editableRow.priceShare) {
      setPriceShareValue(editableRow.priceShare);
    }
  }, [editableRow.priceShare]);

  useEffect(() => {
    if (editableRow.systemType) {
      setSystemTypeValue(editableRow.systemType);
    }
  }, [editableRow.systemType]);

  useEffect(() => {
    if (editableRow.systemPrice) {
      setSystemPriceValue(editableRow.systemPrice);
    }
  }, [editableRow.systemPrice]);

  useEffect(() => {
    if (editableRow.systemKwh) {
      setSystemKwhValue(editableRow.systemKwh);
    }
  }, [editableRow.systemKwh]);

  useEffect(() => {
    if (editableRow.systemShare) {
      setSystemShareValue(editableRow.systemShare);
    }
  }, [editableRow.systemShare]);

  useEffect(() => {
    if (editableRow.epadType) {
      setEpadTypeValue(editableRow.epadType);
    }
  }, [editableRow.epadType]);

  useEffect(() => {
    if (editableRow.epadPrice) {
      setEpadPriceValue(editableRow.epadPrice);
    }
  }, [editableRow.epadPrice]);

  useEffect(() => {
    if (editableRow.epadKwh) {
      setEpadKwhValue(editableRow.epadKwh);
    }
  }, [editableRow.epadKwh]);

  useEffect(() => {
    if (editableRow.epadShare) {
      setEpadShareValue(editableRow.epadShare);
    }
  }, [editableRow.epadShare]);

  useEffect(() => {
    if (editableRow.profileType) {
      setProfileTypeValue(editableRow.profileType);
    }
  }, [editableRow.profileType]);

  useEffect(() => {
    if (editableRow.profilePrice) {
      setProfilePriceValue(editableRow.profilePrice);
    }
  }, [editableRow.profilePrice]);

  useEffect(() => {
    if (editableRow.profileKwh) {
      setProfileKwhValue(editableRow.profileKwh);
    }
  }, [editableRow.profileKwh]);

  useEffect(() => {
    if (editableRow.profileShare) {
      setProfileShareValue(editableRow.profileShare);
    }
  }, [editableRow.profileShare]);

  useEffect(() => {
    if (editableRow.markupPrice) {
      setMarkupPriceValue(editableRow.markupPrice);
    }
  }, [editableRow.markupPrice]);

  useEffect(() => {
    if (editableRow.markupShare) {
      setMarkupShareValue(editableRow.markupShare);
    }
  }, [editableRow.markupShare]);

  useEffect(() => {
    if (editableRow.commissionPrice) {
      setCommissionPriceValue(editableRow.commissionPrice);
    }
  }, [editableRow.commissionPrice]);

  useEffect(() => {
    if (editableRow.commissionShare) {
      setCommissionShareValue(editableRow.commissionShare);
    }
  }, [editableRow.commissionShare]);

  // useEffect(() => {
  //   if (buildingData && buildingData.length > 0) {
  //     let buildingsArray = [{ label: gettext("All"), value: "all" }];
  //     if (buildingData && buildingData.length > 0) {
  //       buildingData.map(building => {
  //         buildingsArray.push(building);
  //         return true;
  //       });
  //       setBuildings(buildingsArray);
  //       // setNewBuildingsData(buildingsArray);
  //     }
  //
  //     if (!buildingValue && buildingData[0]) {
  //       setBuildingValue(buildingData[0].value);
  //       // setBuildingName(buildingData[0].value);
  //       // setBuildingLabel(buildingData[0].label)
  //     }
  //
  //     let meterNumbers = [];
  //     // let allMetersNumbers = [{ value: "all", label: gettext("All") }];
  //     let allMetersNumbers = [];
  //     buildingData.forEach(resp => {
  //       let buildingMeters = {
  //         title: resp.value,
  //         // meterNumbers: [{ value: "all", label: gettext("All") }],
  //         meterNumbers: []
  //       };
  //
  //       // const meters =
  //       //     resp.meters && resp.meters[type] ? resp.meters[type] : [];
  //       const meters = resp && resp[energyTypeValue] ? resp[energyTypeValue] : [];
  //       if (energyTypeValue === "energy") {
  //         resp &&
  //         resp.electricity &&
  //         resp.electricity.map(item => {
  //           const meter = meters.find(
  //             meter => meter?.meterNo === item?.meterNo
  //           );
  //           allMetersNumbers.push({
  //             value: item?.meterNo,
  //             label: meter && meter.label ? meter.label : item?.meterNo
  //           });
  //           buildingMeters.meterNumbers.push({
  //             value: item?.meterNo,
  //             label: meter && meter.label ? meter.label : item?.meterNo
  //           });
  //           return true;
  //         });
  //         resp.heating &&
  //         resp.heating.map(item => {
  //           allMetersNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           buildingMeters.meterNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           return true;
  //         });
  //         resp.water &&
  //         resp.water.map(item => {
  //           allMetersNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           buildingMeters.meterNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           return true;
  //         });
  //       } else {
  //         resp[energyTypeValue] &&
  //         resp[energyTypeValue].map(item => {
  //           allMetersNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           buildingMeters.meterNumbers.push({
  //             value: item?.meterNo,
  //             label: item?.label
  //           });
  //           return true;
  //         });
  //       }
  //
  //       meterNumbers.push(buildingMeters);
  //       return true;
  //     });
  //     if (allMetersNumbers.length) {
  //       allMetersNumbers.unshift({
  //         value: "all",
  //         label: gettext("All")
  //       });
  //     }
  //     if (meterNumbers.length) {
  //       meterNumbers.unshift({ value: "all", label: gettext("All") });
  //     }
  //     setAllMeterNumbers(meterNumbers);
  //     setAllMeterNumbersOnly(allMetersNumbers);
  //
  //     setMeterValue(meterValue);
  //     // setMeterLabel(gettext(meterLabel));
  //     if (buildingValue === "" || buildingValue === "Select" || buildingValue === "all") {
  //       setMeterNumbers(allMetersNumbers);
  //       // setNewBuildingMetersData(allMetersNumbers);
  //     } else {
  //       let currentBuildingInfo = meterNumbers.find(
  //         el => el.title === buildingValue
  //       );
  //       if (currentBuildingInfo?.meterNumbers.length) {
  //         currentBuildingInfo?.meterNumbers.unshift({
  //           value: "all",
  //           label: gettext("All")
  //         });
  //       }
  //
  //       setMeterNumbers(
  //         currentBuildingInfo ? currentBuildingInfo.meterNumbers : []
  //       );
  //       // setNewBuildingMetersData(
  //       //   currentBuildingInfo ? currentBuildingInfo.meterNumbers : []
  //       // );
  //     }
  //     // let addressArray = buildingData.map((obj, index) => {
  //     //   return obj.value;
  //     // });
  //     // setAddressesList(addressArray.join("|"));
  //   }
  // }, [
  //   buildingData,
  //   // buildingName,
  //   buildingValue,
  //   gettext,
  //   energyTypeValue,
  //   meterValue
  //   // meterLabel
  // ]);

  //endregion useEffects

  //region Form actions
  const resetForm = useCallback(() => {
    setPriceTypeValue(defaultValues.priceType);
    setPricePriceValue(defaultValues.pricePrice);
    setPriceKwhValue(defaultValues.priceKwh);
    setPriceShareValue(defaultValues.priceShare);

    setSystemTypeValue(defaultValues.systemType);
    setSystemPriceValue(defaultValues.systemPrice);
    setSystemKwhValue(defaultValues.systemKwh);
    setSystemShareValue(defaultValues.systemShare);

    setEpadTypeValue(defaultValues.epadType);
    setEpadPriceValue(defaultValues.epadPrice);
    setEpadKwhValue(defaultValues.epadKwh);
    setEpadShareValue(defaultValues.epadShare);

    setProfileTypeValue(defaultValues.profileType);
    setProfilePriceValue(defaultValues.profilePrice);
    setProfileKwhValue(defaultValues.profileKwh);
    setProfileShareValue(defaultValues.profileShare);

    setMarkupPriceValue(defaultValues.markupPrice);
    setMarkupShareValue(defaultValues.markupShare);

    setCommissionPriceValue(defaultValues.commissionPrice);
    setCommissionShareValue(defaultValues.commissionShare);
  }, [defaultValues.commissionPrice, defaultValues.commissionShare, defaultValues.epadKwh, defaultValues.epadPrice, defaultValues.epadShare, defaultValues.epadType, defaultValues.markupPrice, defaultValues.markupShare, defaultValues.priceKwh, defaultValues.pricePrice, defaultValues.priceShare, defaultValues.priceType, defaultValues.profileKwh, defaultValues.profilePrice, defaultValues.profileShare, defaultValues.profileType, defaultValues.systemKwh, defaultValues.systemPrice, defaultValues.systemShare, defaultValues.systemType]);

  const {
    addDeal,
    loading: updating
  } = useAddDeal(
    () => {
      enqueueSnackbar(gettext("The new deal has added"), {
        variant: "success"
      });
      resetForm();
      navigate("/data/deals");
      // handleClose();
    },
    error => {
      // if (error === "409") setEmailError(true);
      // else if (error === "410")

      if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        enqueueSnackbar(gettext("Error: Something went wrong!"), {
          variant: "error"
        });

        const fields = [
          // {
          //   title: "User ID",
          //   value: userId
          // },
          // {
          //   title: "User Name",
          //   value: userName
          // },
          // {
          //   title: "User Email",
          //   value: userEmail
          // },
          // {
          //   title: "User Team",
          //   value: teamName
          // },
          // {
          //   title: "Environment",
          //   value: process.env.REACT_APP_NODE_ENV
          // }
        ];

        const slackErrorData = {
          channel: "error",
          type: "error",
          heading: `Runtime error on Add Deal saving`,
          detail: `${error.split('\n').join('\\n')}`,
          fields: fields
        };
        sendSlackMessage(slackErrorData);
      }
    }
  );

  const {editDeal} = useEditDeal(
    () => {
      enqueueSnackbar(gettext("The deal has updated"), {
        variant: "success"
      });
      resetForm();
      navigate("/data/deals");
      // handleClose();
    },
    error => {
      // if (error === "409") setEmailError(true);
      // else if (error === "410")
      if (error === "410") {
        enqueueSnackbar(gettext("You are not authorized to perform this action"), {
          variant: "error"
        });
      } else {
        // enqueueSnackbar(gettext("Error: Something went wrong!"), {
        //   variant: "error"
        // });

        enqueueSnackbar(error, {
          variant: "error"
        });

        const fields = [
          // {
          //   title: "User ID",
          //   value: userId
          // },
          // {
          //   title: "User Name",
          //   value: userName
          // },
          // {
          //   title: "User Email",
          //   value: userEmail
          // },
          // {
          //   title: "User Team",
          //   value: teamName
          // },
          // {
          //   title: "Environment",
          //   value: process.env.REACT_APP_NODE_ENV
          // }
        ];

        const slackErrorData = {
          channel: "error",
          type: "error",
          heading: `Runtime error on Edit Deal saving `,
          detail: `${error.split('\n').join('\\n')}`,
          fields: fields
        };
        sendSlackMessage(slackErrorData);
      }
    }
  );

  const submitHandler = e => {
    e.preventDefault();

    if (!validateSubmit()) {
      return false;
    }

    const variables = {
      customer: currentSubsidiary,

      building: buildingValue,
      meter: meterValue || 0,
      energyType: energyTypeValue || "electricity",
      fromDate: formatDateForAPI(fromDateValue),
      toDate: formatDateForAPI(toDateValue, "23:59:59"),
      currency: currencyValue,
      mainPriceType: mainPriceTypeValue,

      priceType: priceTypeValue,
      pricePrice: parseFloat(pricePriceValue) || 0,
      priceKwh: parseFloat(priceKwhValue) || 0,
      priceShare: parseFloat(priceShareValue) || 0,

      systemType: systemTypeValue,
      systemPrice: parseFloat(systemPriceValue) || 0,
      systemKwh: parseFloat(systemKwhValue) || 0,
      systemShare: parseFloat(systemShareValue) || 0,

      epadType: epadTypeValue,
      epadPrice: parseFloat(epadPriceValue) || 0,
      epadKwh: parseFloat(epadKwhValue) || 0,
      epadShare: parseFloat(epadShareValue) || 0,

      profileType: profileTypeValue,
      profilePrice: parseFloat(profilePriceValue) || 0,
      profileKwh: parseFloat(profileKwhValue) || 0,
      profileShare: parseFloat(profileShareValue) || 0,

      markupPrice: parseFloat(markupPriceValue) || 0,
      markupShare: parseFloat(markupShareValue) || 0,

      commissionPrice: parseFloat(commissionPriceValue) || 0,
      commissionShare: parseFloat(commissionShareValue) || 0
    };

    if (editableRow.Id) {
      variables["id"] = editableRow.Id;
      editDeal({
        variables: variables,
        refetchQueries: ["GetAllDeals"]
      }).then();
    } else {
      addDeal({
        variables: variables,
        refetchQueries: ["GetAllDeals"]
      }).then();
    }

  };

  const validateSubmit = () => {
    let returnValue = true;

    if (!buildingValue) {
      enqueueSnackbar(gettext("Building is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!meterValue) {
      enqueueSnackbar(gettext("Installation number is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!energyTypeValue) {
      enqueueSnackbar(gettext("Energy type is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!fromDateValue) {
      enqueueSnackbar(gettext("From date is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!toDateValue) {
      enqueueSnackbar(gettext("To date is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!currencyValue) {
      enqueueSnackbar(gettext("Currency is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    if (!mainPriceTypeValue) {
      enqueueSnackbar(gettext("Price type is required"), {
        variant: "error"
      });
      returnValue = false;
    }

    return returnValue;
  };

  //region Value Change Handlers

  //region Value Change Filters
  const buildingValueChange = building => {
    setBuildingValue(building.value);

    // let selectedBuildingValue = building.value;
    // let selectedBuildingName = building.label;
    // setBuildingValue(selectedBuildingValue);

    // if (selectedBuildingValue === "") {
    //   setMeterNumbers(allMeterNumbersOnly);
    //   // setNewBuildingMetersData(allMeterNumbersOnly);
    // } else {
    //   let currentBuildingInfo = allMeterNumbers.find(
    //     el => el.title === selectedBuildingName
    //   );
    //
    //   // if (currentBuldingInfo?.meterNumbers.length) {
    //   //   currentBuldingInfo?.meterNumbers.unshift({
    //   //     value: "all",
    //   //     label: "All"
    //   //   });
    //   // }
    //
    //   setMeterNumbers(
    //     currentBuildingInfo ? currentBuildingInfo.meterNumbers : []
    //   );
    //   // setNewBuildingMetersData(
    //   //   currentBuildingInfo ? currentBuildingInfo.meterNumbers : []
    //   // );
    // }
    // setMeterValue("");
    // // setMeterLabel("Select");
    // // setIsError(false);
    // // setIsSuccess(false);
    // // setStatusMessage("");
  };

  const meterValueChange = selected => {
    setMeterValue(selected.value);
  };

  const energyTypeValueChange = selected => {
    setEnergyTypeValue(selected.value);
  };

  // const validDateValueChange = event => {
  //   const [start, end] = event;
  //   setFromDateValue(start);
  //   setToDateValue(end);
  // };

  const validDateValueChangeStart = event => {
    setFromDateValue(event);
    // const [start, end] = event;
    // setFromDateValue(start);
    // setToDateValue(end);
  };

  const validDateValueChangeEnd = event => {
    setToDateValue(event);
    // const [start, end] = event;
    // setFromDateValue(start);
    // setToDateValue(end);
  };

  const currencyValueChange = selected => {
    setCurrencyValue(selected.value);
    switch (selected.value) {
      case "gbp":
        setPriceColumnName(gettext("Price (pence/kWh)"));
        break;
      case "usd":
      case "eur":
        setPriceColumnName(gettext("Price (cent/kWh)"));
        break;
      case "sek":
      case "nok":
      case "dkk":
      default:
        setPriceColumnName(gettext("Price (øre/kWh)"));
        break;
    }
  };

  const mainPriceTypeValueChange = selected => {
    setMainPriceTypeValue(selected.value);
    setShowPrice(selected.price);
    setShowSystem(selected.system);
    setShowEpad(selected.epad);
    setShowProfile(selected.profile);
    setShowMarkup(selected.markup);
    setShowCommission(selected.commission);
    setPriceTypeValue(selected.price ? "fixed" : "variable");
    setSystemTypeValue(selected.system ? "fixed" : "variable");
    setEpadTypeValue(selected.epad ? "fixed" : "variable");
    setProfileTypeValue(selected.profile ? "fixed" : "variable");
  };
  //endregion Value Change Filters

  //region Value Change Price Row
  const pricePriceValueChange = (event) => {
    setPricePriceValue(event.target.value);
  };

  const priceKwhValueChange = (event) => {
    setPriceKwhValue(event.target.value);
    updateAllQuantity(event.target.value);
  };

  const priceShareValueChange = (event) => {
    setPriceShareValue(event.target.value);
    updateAllShare(event.target.value);
  };
  //endregion Value Change Price Row

  //region Value Change System Row
  const systemPriceValueChange = (event) => {
    setSystemPriceValue(event.target.value);
  };

  const systemKwhValueChange = (event) => {
    setSystemKwhValue(event.target.value);
    updateAllQuantity(event.target.value);
  };

  const systemShareValueChange = (event) => {
    setSystemShareValue(event.target.value);
    updateAllShare(event.target.value);
  };
  //endregion Value Change System Row

  //region Value Change Epad
  const epadPriceValueChange = (event) => {
    setEpadPriceValue(event.target.value);
  };

  const epadKwhValueChange = (event) => {
    setEpadKwhValue(event.target.value);
    updateAllQuantity(event.target.value);
  };

  const epadShareValueChange = (event) => {
    setEpadShareValue(event.target.value);
    updateAllShare(event.target.value);
  };
  //endregion Value Change Epad

  //region Value Change Profile
  const profilePriceValueChange = (event) => {
    setProfilePriceValue(event.target.value);
  };

  const profileKwhValueChange = (event) => {
    setProfileKwhValue(event.target.value);
    updateAllQuantity(event.target.value);
  };

  const profileShareValueChange = (event) => {
    setProfileShareValue(event.target.value);
    updateAllShare(event.target.value);
  };
  //endregion Value Change Profile

  //region Value Change Markup Row
  const markupPriceValueChange = (event) => {
    setMarkupPriceValue(event.target.value);
  };

  // const markupShareValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setMarkupShareValue(event.target.value);
  // };
  //endregion Value Change Markup Row

  //region Value Change Commission Row
  const commissionPriceValueChange = (event) => {
    setCommissionPriceValue(event.target.value);
  };

  // const commissionShareValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setCommissionShareValue(event.target.value);
  // };
  //endregion Value Change Commission Row

  //region Update Common Fields
  const updateAllShare = (value) => {
    if (value > 100) value = 100;
    if (value < 0) value = 0;
    if (value < 100) {
      updateAllQuantity(0);
    }
    setPriceShareValue(value);
    setSystemShareValue(value);
    setEpadShareValue(value);
    setProfileShareValue(value);
  };

  const updateAllQuantity = (value) => {
    if (value < 0) value = 0;
    if (value > 0) {
      updateAllShare(100);
    }
    setPriceKwhValue(value);
    setSystemKwhValue(value);
    setEpadKwhValue(value);
    setProfileKwhValue(value);
  };
  //endregion UpdateCommon Fields

  //endregion Change handlers

  return (
    <Box sx={{
      width: "auto"
    }}>
      <form onSubmit={submitHandler}>
        <Stack spacing={3} sx={{
          height: "100%"
        }}>
          <Typography variant="h4">
            {gettext("Create New Contract")}
            {/*{!props.row.id && gettext("Create New Contract")}*/}
            {/*{props.row.id && gettext("Edit Contract")}*/}
          </Typography>

          <Box sx={{
            height: "100%"
          }}>
            <FilterBox>
              <Grid container spacing={3}>
                {/* Building Dropdown Start */}
                <Grid desc="Building dropdown" item xs={12} md={4}>
                  <BuildingDropdownSimple
                    onChange={buildingValueChange}
                    defaultValue={buildingValue}
                    showAll={true}
                  />
                </Grid>
                {/* Building Dropdown End */}

                {/* Meter Dropdown Start */}
                <Grid item xs={12} md={4}>
                  <MeterDropdownSimple
                    onChange={meterValueChange}
                    energyType={"electricity"}
                    building={buildingValue}
                    defaultValue={meterValue}
                    showAll={true}
                  />
                </Grid>
                {/* Meter Dropdown End */}

                {/* Energy Type Dropdown Start */}
                <Grid item xs={12} md={4}>
                  <EnergyTypeDropdownSimple
                    onChange={energyTypeValueChange}
                    defaultValue={energyTypeValue}
                    showAll={false}
                  />
                </Grid>
                {/* Energy Type Dropdown End */}

                {/* region Date Picker (From) Start */}
                <Grid item xs={12} md={4}>
                  <SingleDatePicker
                    componentLabel={gettext("Start Date")}
                    selectedDate={fromDateValue}
                    onValueChange={validDateValueChangeStart}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <SingleDatePicker
                    componentLabel={gettext("End Date")}
                    selectedDate={toDateValue}
                    onValueChange={validDateValueChangeEnd}
                  />
                </Grid>


                {/*<Grid item xs={12} md={4}>*/}
                {/*  <MultiDatePicker*/}
                {/*    // selected={[fromDateValue, toDateValue]}*/}
                {/*    defaultStartDate={fromDateValue}*/}
                {/*    defaultEndDate={toDateValue}*/}
                {/*    onValueChange={validDateValueChange}*/}
                {/*    // dateFormat="dd MMMM yyyy"*/}
                {/*    componentLabel={gettext("Validating period")}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/* endregion Date Picker */}

                {/* Energy Type Dropdown Start */}
                <Grid item xs={12} md={4}>
                  <CurrencyDropdownSimple
                    defaultValue={currencyValue}
                    onChange={currencyValueChange}
                    showAll={false}
                  />
                </Grid>
                {/* Energy Type Dropdown End */}

                {/* Energy Type Dropdown Start */}
                <Grid item xs={12} md={4}>
                  <PriceTypeDropdownSimple
                    countryCode={countryCode}
                    onChange={mainPriceTypeValueChange}
                    defaultValue={mainPriceTypeValue}
                    showAll={false}
                  />
                </Grid>
                {/* Energy Type Dropdown End */}
              </Grid>
            </FilterBox>
            <Grid marginTop={2}></Grid>
            <TableBox sx={{
              p: 2
            }}>
              {/*<Typography fontSize={20} fontWeight={700} marginBottom={2} marginTop={1}>*/}
              {/*  {gettext("Add Contract")}*/}
              {/*</Typography>*/}

              <Grid container spacing={2}>
                {/*<div className={"table-responsive"}>*/}
                {/*  <table className={"table"} cellPadding={5} style={{*/}
                {/*    width: "100%"*/}
                {/*  }}>*/}
                {/*<thead>*/}
                {/*  <tr style={{*/}
                {/*    textAlign: "left"*/}
                {/*  }}>*/}
                {/*    <th>{gettext("Price elements")}</th>*/}
                {/*    <th>{gettext("Type")}</th>*/}
                {/*    <th>{priceColumnName}</th>*/}
                {/*    <th>{gettext("Quantity (kWh)")}</th>*/}
                {/*    <th>{gettext("Share (%)")}</th>*/}
                {/*  </tr>*/}
                {/*</thead>*/}

                {/*<tbody>*/}
                {showPrice && <>
                  {/*<td>{gettext("Price")}</td>*/}
                  {/*<td>*/}
                  {/*  {gettext("Fixed")}*/}
                  {/*</td>*/}
                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("Price")}
                    </Typography>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={pricePriceValue}
                        onChange={pricePriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Quantity (kWh)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={priceKwhValue}
                        onChange={priceKwhValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Share (%)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={priceShareValue}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 100
                          }
                        }}
                        onChange={priceShareValueChange}
                      />
                    </Grid>
                  </Grid>
                  {/*<Grid container item spacing={3}>*/}
                  {/*  <Grid item xs={12}></Grid>*/}
                  {/*</Grid>*/}
                </>}
                {/*</tr>}*/}
                {showSystem && <>
                  {/*<td>{gettext("System")}</td>*/}
                  {/*<td>*/}
                  {/*  {gettext("Fixed")}*/}
                  {/*</td>*/}
                  {/*<Grid container item>*/}
                  {/*  <Grid item xs={12}>*/}
                  {/*    <Typography sx={{*/}
                  {/*      fontWeight: "700"*/}
                  {/*    }}>*/}
                  {/*      {gettext("System")}*/}
                  {/*    </Typography>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                  {/*<Grid container item spacing={3}>*/}

                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("System")}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={systemPriceValue}
                        onChange={systemPriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Quantity (kWh)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={systemKwhValue}
                        onChange={systemKwhValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Share (%)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={systemShareValue}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 100
                          }
                        }}
                        onChange={systemShareValueChange}
                      />
                    </Grid>
                  </Grid>
                  {/*<Grid container item spacing={3}>*/}
                  {/*  <Grid item xs={12}></Grid>*/}
                  {/*</Grid>*/}
                </>}
                {/*</tr>}*/}
                {showEpad && <>
                  {/*<td>{gettext("EPAD")}</td>*/}
                  {/*<td>*/}
                  {/*  {gettext("Fixed")}*/}
                  {/*</td>*/}
                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("EPAD")}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={epadPriceValue}
                        onChange={epadPriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Quantity (kWh)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={epadKwhValue}
                        onChange={epadKwhValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Share (%)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={epadShareValue}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 100
                          }
                        }}
                        onChange={epadShareValueChange}
                      />
                    </Grid>
                  </Grid>
                  {/*<Grid container item spacing={3}>*/}
                  {/*  <Grid item xs={12}></Grid>*/}
                  {/*</Grid>*/}
                </>}
                {/*</tr>}*/}
                {showProfile && <>
                  {/*<td>{gettext("Profile")}</td>*/}
                  {/*<td>*/}
                  {/*  {gettext("Fixed")}*/}
                  {/*</td>*/}
                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("Profile")}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={profilePriceValue}
                        onChange={profilePriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Quantity (kWh)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={profileKwhValue}
                        onChange={profileKwhValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={gettext("Share (%)")}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={profileShareValue}
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 100
                          }
                        }}
                        onChange={profileShareValueChange}
                      />
                    </Grid>
                  </Grid>
                </>}
                {/*</tr>}*/}
                {showMarkup && <>
                  {/*<td>{gettext("Markup")}</td>*/}
                  {/*<td>{gettext("Fixed")}</td>*/}
                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("Markup")}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={markupPriceValue}
                        onChange={markupPriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/*{gettext("All kWh")}*/}
                      <TextField
                        label={gettext("Quantity (kWh)")}
                        fullWidth
                        disabled
                        value={gettext("All kWh")}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label={gettext("Share (%)")}
                        fullWidth
                        disabled
                        value={gettext("Not Applicable")}
                      />
                      {/*{gettext("Not Applicable")}*/}
                      {/* <TextField */}
                      {/*   type="number" */}
                      {/*   label={gettext("Share")} */}
                      {/*   placeholder={gettext("0.00")} */}
                      {/*   size="small" */}
                      {/*   value={markupShareValue} */}
                      {/*   InputProps={{ inputProps: { min: 0, max: 100 } }} */}
                      {/*   onChange={markupShareValueChange} */}
                      {/* /> */}
                    </Grid>
                  </Grid>
                </>}
                {/*</tr>}*/}
                {showCommission && <>
                  {/*<td>{gettext("Spot premium")}</td>*/}
                  {/*<td>{gettext("Fixed")}</td>*/}
                  <Grid item xs={12} sx={{
                    marginTop: 0
                  }}>
                    <Typography sx={{
                      fontWeight: "700"
                    }}>
                      {gettext("Spot premium")}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        type="number"
                        label={priceColumnName}
                        placeholder={gettext("0.00")}
                        fullWidth
                        value={commissionPriceValue}
                        onChange={commissionPriceValueChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/*{gettext("All kWh")}*/}
                      <TextField
                        label={gettext("Quantity (kWh)")}
                        fullWidth
                        disabled
                        value={gettext("All kWh")}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label={gettext("Share (%)")}
                        fullWidth
                        disabled
                        value={gettext("Not Applicable")}
                      />
                      {/*{gettext("Not Applicable")}*/}
                      {/* <TextField */}
                      {/*   type="number" */}
                      {/*   label={gettext("Share")} */}
                      {/*   placeholder={gettext("0.00")} */}
                      {/*   size="small" */}
                      {/*   value={commissionShareValue} */}
                      {/*   InputProps={{ inputProps: { min: 0, max: 100 } }} */}
                      {/*   onChange={commissionShareValueChange} */}
                      {/* /> */}
                    </Grid>
                  </Grid>
                </>}
                {/*  </tr>}*/}
                {/*  </tbody>*/}
                {/*</table>*/}
                {/*</div>*/}
              </Grid>
            </TableBox>
          </Box>
          <Paper elevation={0} sx={{
            textAlign: "right",
          }}>
            <Button
              variant="outlined"
              // fullWidth
              // onClick={() => handleCancel()}
              sx={{
                margin: "5px",
                minWidth: 164,
                maxWidth: "100%"
              }}
              component={Link} to={"/data/deals"}
            >
              {gettext("Cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              loading={updating}
              // fullWidth
              type="submit"
              sx={{
                margin: "5px",
                minWidth: 164,
                maxWidth: "100%"
              }}
            >
              {editableRow.Id ? gettext("Update") : gettext("Add")}
            </LoadingButton>

          </Paper>
        </Stack>
      </form>
    </Box>
    // </Drawer>
  );
};
