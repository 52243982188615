import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useRedirectStats} from "hooks";

// import { LayoutSplashScreen } from "_metronic/layout";
/**
 *
 */
export const RedirectFlow = (props) => {
  const params = useParams();
  // const {user} = users();
  const customerKey = params.customerKey;
  const userType = params.type || "company";
  const email = params.email || "";
  const thirdPartyId = "f6e5c2b0-a41c-4edb-b250-a432debdbaaa";

  // const [redirect, setRedirect] = useState(false);

  // const [redirectUrl, setRedirectUrl] = useState("/dashboard")

  const {redirectStats} = useRedirectStats(data => {
    const redirectUrl = "https://eloverblik.dk/authorization/login?thirdPartyId=" +
      thirdPartyId +
      "&fromDate=" +
      data.fromDate +
      "&toDate=" +
      data.toDate +
      "&customerKey=" +
      customerKey +
      "&userEmail=" +
      email +
      // "&userType=" +
      // userType +
      "&returnUrl=https://api.fabeke.com/v2/admin";

    window.location.href = redirectUrl;


    // window.location.href =
    //   "https://eloverblik.dk/Authorization/login?thirdPartyId=e899fcd8-2129-4594-a077-c27a51d9dfd3&fromDate=" +
    //   data.fromDate +
    //   "&toDate=" +
    //   data.toDate +
    //   "&customerKey=" +
    //   customerKey +
    //   "&userEmail=" +
    //   email +
    //   "&userType=" +
    //   userType +
    //   "&returnUrl=https://api.fabeke.com/v2/admin";
  });

  useEffect(() => {
    redirectStats({
      customerKey,
      email,
      userType
    });
  }, [redirectStats, customerKey, email, userType]);

  return (
    <>
      {/* // redirect && ( */}
      {/* // <Suspense fallback={<LayoutSplashScreen />}> */}
      {/* // <Routes> */}
      {/* // <Navigate to={redirectUrl} /> */}
      {/* // </Routes> */}
      {/* // </Suspense> */}
      {/* // ) */}
      {/* <LayoutSplashScreen /> */}
    </>
  );
};

