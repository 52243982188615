import {Box, Skeleton} from "@mui/material";
import React from "react";

export const TableLoadingStateEnergyStatement = () => {
  return (
    <>
      <Box
        width="100%"
        height="60px"
        sx={{
          display: "flex",
          flexDirection: "row", // Aligns items in a row
          justifyContent: "space-between", // Spreads them apart
          gap: 1,
          mt: 3
        }}
      >
        <Skeleton variant="rounded" animation="wave" width="400px" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="400px" height="60px"/>
      </Box>
      <Box
        width="100%"
        height="100vh"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mt: 1,
          p: 1,
          border: 1,
          borderColor: "grey.300",
          borderRadius: '6px'
        }}
      >
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px" sx={{
          bgcolor: 'grey.400'
        }}/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
        <Skeleton variant="rounded" animation="wave" width="100%" height="60px"/>
      </Box>
    </>
  );
};

