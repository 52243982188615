import {useTextDomainContext} from "../../providers";
import {useContext} from "react";
import {BlueButtonStyleHeading, CountryTimezoneForm} from "../";
import {ModalBox} from "./ModalBox";
import {Typography} from "@mui/material";
import {getPartnerName} from "../../../utils";
import {Box} from "@mui/system";
import PropTypes from "prop-types";

export const CountryTimezoneModal = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const partnerName = getPartnerName();

  return (
    <ModalBox open={props.open} handleClose={props.handleClose}>
      <Box sx={{
        backgroundColor: (theme) => theme.palette.grey[50],
        p: 4
      }}>
        <Box sx={{
          textAlign: "center",
          pb: 3
        }}>
          <BlueButtonStyleHeading>{gettext("Account Setup")}</BlueButtonStyleHeading>
        </Box>

        <Typography sx={{
        }}>
          {gettext("Select your locality")}, {partnerName} {gettext("converts data into your language and local time zone")}.
        </Typography>
      </Box>
      <Box sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        p: 4
      }}>
        <Typography variant="h4" sx={{
          textAlign: "center",
          pb: 4
        }}>
          {gettext("Welcome to")} {partnerName}
        </Typography>
        <CountryTimezoneForm handleClose={props.handleClose}/>
      </Box>
    </ModalBox>
  );
};

CountryTimezoneModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};