import React, {useRef, useState} from "react";
import {Box} from "@mui/system";
import {Avatar, Grid, IconButton, Typography} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {useUser} from "../../providers";
import PropTypes from "prop-types";
import {nameInitial} from "../../../utils";

export const PictureUploadBox = (props) => {
  //region Data
  const inputRef = useRef(null);
  const {user} = useUser();
  //endregion Data

  //region React Hook (useState)
  const [photoChanged, setPhotoChanged] = useState(false);
  const [dataUri, setDataUri] = useState(undefined);
  //endregion React Hook (useState)

  //region Handlers
  const handlePictureClick = () => {
    // fileInputRef.current.click();
    inputRef.current.click();
  };

  const changePic = event => {
    const fileToDataUri = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });

    if (event.currentTarget.files[0]) {
      fileToDataUri(event.currentTarget.files[0]).then(dataUri => {
        setPhotoChanged(true);
        setDataUri(dataUri);
        if (typeof props.onChange === "function") {
          props.onChange(dataUri);
        }
      });
    }
  };
  //endregion Handlers

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{
          backgroundColor: "gray.50",
          border: 1,
          borderColor: "gray.200",
          height: 225,
          width: "100%",
          minHeight: 100,
          maxHeight: 225,
          position: 'relative',
          display: "flex",
          justifyContent: "center",
        }}>
          <IconButton onClick={handlePictureClick} sx={{
            position: 'absolute',
            bottom: 0,
            right: 0
          }}>
            <AddAPhotoIcon/>
          </IconButton>
          <Avatar
            src={photoChanged ? dataUri : user.profilePhotoUrl}
            alt="Profile Picture"
            sx={{
              width: 150,
              height: 150,
              cursor: 'pointer',
              m: 5
            }}
            onClick={handlePictureClick}
          >
            {nameInitial(user?.name)}
          </Avatar>
          <input
            type="file"
            name="photo1"
            id="photo1"
            accept=".png, .jpg, .jpeg"
            onChange={changePic}
            ref={inputRef}
            disabled={user.demo}
            style={{
              display: "none"
            }}
          />
          <input
            type="hidden"
            name="profile_avatar_remove"
            disabled={user.demo}
          />
          {/*<input*/}
          {/*  type="file"*/}
          {/*  accept="image/*"*/}
          {/*  style={{*/}
          {/*    display: 'none'*/}
          {/*  }}*/}
          {/*  ref={fileInputRef}*/}
          {/*  onChange={handleFileChange}*/}
          {/*/>*/}
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="span" sx={{
          fontWeight: 300,
          fontSize: "0.9rem"
        }}>
          File type:
        </Typography>&nbsp;
        <Typography variant="span" sx={{
          fontWeight: 400,
          fontSize: "0.9rem"
        }}>
          PNG, JPG, JPEG
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="span" sx={{
            fontWeight: 300,
            fontSize: "0.9rem"
          }}>
            Upload Limit:
          </Typography>&nbsp;
          <Typography variant="span" sx={{
            fontWeight: 400,
            fontSize: "0.9rem"
          }}>
            15MB
          </Typography>

        </Box>
      </Grid>
    </Grid>

  );
};

PictureUploadBox.propsType = {
  onChange: PropTypes.func.isRequired
};