import {useLang, useTextDomainContext, useUser} from "../../providers";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {FilterBar1} from "../../components";
import {EnergyOverallStatement} from "./EnergyOverallStatement";
import {EnergyMonthlyStatement} from "./EnergyMonthlyStatement";
import {useConsumptionEnergyData} from "../../../hooks";
import {energyMonthlyColumns,
  energyMonthlyRows,
  getCacheWithExpiry,
  toLocaleNumber,
  urlFriendlyString} from "../../../utils";
// import {useTheme} from "@mui/material";
// import {Segments} from "../../../utils";
// import {useMetersData} from "../../../hooks";

// const metersData = [];
// const loading = false;
// metersData = AddIdToArrayOfObjects(metersData);

export const EnergyStatement = (props) => {
  // console.error("Test");
  // console.log(AddIdToArrayOfObjects(metersData));
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const locale = useLang();
  const {user} = useUser();
  // const segmentList = Segments();
  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {
  };
  // const theme = useTheme();
  if (!customer) {
    customer = "all";
  }

  if (!group) {
    group = "All";
  }

  //region React Hooks (useState)
  const [energyType, setEnergyType] = useState("electricity");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState('jan');
  const [isClimate, setIsClimate] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [monthColumns, setMonthColumns] = useState([]);
  const [monthRowsData, setMonthRowsData] = useState([]);
  const [yearRowsData, setYearRowsData] = useState([]);
  // const [data, setData] = useState([]);
  // const [cacheKeyMonthColumns, setCacheKeyMonthColumns] = useState(`energy-statement-${energyType}-${customer}-${year}-month-columns`);
  // const [cacheKeyMonthRows, setCacheKeyMonthRows] = useState(`energy-statement-${energyType}-${customer}-${year}-month-rows`);
  // const [cacheKeyYearRows, setCacheKeyYearRows] = useState(`energy-statement-${energyType}-${customer}-${year}-year-rows`);
  // const [loadedCacheMonth, setLoadedCacheMonth] = useState(false);
  // const [loadedCacheYear, setLoadedCacheYear] = useState(false);

  // const [selectedEnergyStatement, setSelectedEnergyStatement] = useState("overall-statement"); // overall-statement, monthly-statement

  // const [building, setBuilding] = useState("all");
  // const [meter, setMeter] = useState("all");
  // const [fromDate, setFromDate] = useState();
  // const [toDate, setToDate] = useState();
  // const [segment, setSegment] = useState("year");
  // const [shortSegment, setShortSegment] = useState("m");
  // const [chartData, setChartData] = useState([]);
  const [fileNameForExport, setFileNameForExport] = useState("energy-statement");
  //endregion  React Hooks (useState)

  //region Data
  const filters = {
    meterType: energyType,
    customer: customer,
    group: group,
    year: year,
    isClimate: isClimate,
  };

  const {
    data,
    loading
  } = useConsumptionEnergyData(filters);

  const yearColumns = useMemo(() => {
    return [
      {
        field: "meter",
        headerName: gettext("Order No"),
        width: 200,
      },
      {
        field: "meterNumber",
        headerName: gettext("Meter No"),
        width: 150,
      },
      {
        field: "customer",
        headerName: gettext("Company"),
        width: 250,
      },
      // {
      //   field: "address",
      //   headerName: gettext("Address"),
      //   width: 300,
      // },
      {
        field: "streetName",
        headerName: gettext("Street"),
        width: 200,
      },
      {
        field: "buildingNumber",
        headerName: gettext("House No."),
        width: 100,
      },
      {
        field: "postcode",
        headerName: gettext("Zip Code"),
        width: 100,
      },
      {
        field: "cityName",
        headerName: gettext("City"),
        width: 150,
      },
      {
        field: "consumerCVR",
        headerName: gettext("Company No."),
        width: 100,
      },

      {
        field: "total",
        headerName: gettext("Sum"),
        // flex: 1,
        width: 150,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jan",
        headerName: gettext("January"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "feb",
        headerName: gettext("February"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "mar",
        headerName: gettext("March"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "apr",
        headerName: gettext("April"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "may",
        headerName: gettext("May"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jun",
        headerName: gettext("June"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "jul",
        headerName: gettext("July"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "aug",
        headerName: gettext("August"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "sep",
        headerName: gettext("September"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "oct",
        headerName: gettext("October"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "nov",
        headerName: gettext("November"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
      {
        field: "dec",
        headerName: gettext("December"),
        // flex: 1,
        width: 100,
        type: "number",
        valueFormatter: (value) => toLocaleNumber(locale, value, 0),
      },
    ];
  }, [gettext, locale]);
  //endregion Data

  //region React Hooks (useEffect)

  // useEffect(() => {
  //   if (!isEqual(data, responseData)) {
  //     setData(responseData);
  //   }
  // }, [data, responseData]);

  useEffect(() => {
    const key = `energy-statement-${energyType}-${customer}-${year}-month-columns`;
    // setCacheKeyMonthColumns(key);
    getCacheWithExpiry(key).then(value => {
      if (value) {
        setMonthColumns(value);
        // setLoadedCacheMonth(true);
      }
    });
  }, [customer, energyType, year]);

  useEffect(() => {
    const key = `energy-statement-${energyType}-${customer}-${year}-month-rows`;
    // setCacheKeyMonthRows(key);
    getCacheWithExpiry(key).then(value => {
      if (value) {
        setMonthRowsData(value);
      }
    });
  }, [customer, energyType, year]);

  useEffect(() => {
    const key = `energy-statement-${energyType}-${customer}-${year}-year-rows`;
    // setCacheKeyYearRows(key);
    getCacheWithExpiry(key).then(value => {
      if (value) {
        setYearRowsData(value);
        // setLoadedCacheYear(true);
      }
    });
  }, [customer, energyType, year]);

  useEffect(() => {
    if (data) {
      setDataReceived(true);
      // const blob = new Blob([JSON.stringify(data, null, 2)], {
      //   type: 'application/json'
      // });
      //
      // saveFile(blob).then(() => {
      //   // console.log("File is ready");
      // });

      const columnsData = energyMonthlyColumns(data, gettext, locale);
      const allRowsData = energyMonthlyRows(data, gettext);

      if (columnsData?.length) {
        setMonthColumns(columnsData);
        // setCacheWithExpiry(cacheKeyMonthColumns, JSON.stringify(columnsData), 3600);
      } else {
        setMonthColumns([]);
        // setCacheWithExpiry(cacheKeyMonthColumns, null, 10);
      }

      if (allRowsData["all"]?.length) {
        setMonthRowsData(allRowsData);
        // setCacheWithExpiry(cacheKeyMonthRows, allRowsData, 3600);
      }
    }
  }, [data, gettext, locale]);

  useEffect(() => {
    const allRowsData = data?.consumptionEnergyData?.aggregatedData;
    if (allRowsData) {
      setYearRowsData(allRowsData);
      // setCacheWithExpiry(cacheKeyYearRows, allRowsData, 3600);
    }
  }, [data, gettext, locale]);

  useEffect(() => {
    const excelDataArray = [
      {
        title: gettext("Overall statement"),
        columns: yearColumns,
        rows: yearRowsData,
        customer: {
          name: customer + " - " + year,
          logo: {
            // file: "/media/partners/fabeke/logos/logo-dark.png",
            file: "",
            extension: "png",
            width: 100,
            height: 50,
            link: "https://app.fabeke.com",
          }
        },
        partner: {
          name: "Energi Team ApS",
          logo: {
            file: "/media/partners/energiteam/logos/logo-energy-team-one-line.png",
            extension: "png",
            width: 393,
            height: 62,
            link: "https://app.energiteam.dk",
          }
        },
      },
      {
        title: gettext("January"),
        columns: monthColumns,
        rows: monthRowsData?.jan ? monthRowsData.jan : []
      },
      {
        title: gettext("February"),
        columns: monthColumns,
        rows: monthRowsData?.feb ? monthRowsData.feb : []
      },
      {
        title: gettext("March"),
        columns: monthColumns,
        rows: monthRowsData?.mar ? monthRowsData.mar : []
      },
      {
        title: gettext("April"),
        columns: monthColumns,
        rows: monthRowsData?.apr ? monthRowsData.apr : []
      },
      {
        title: gettext("May"),
        columns: monthColumns,
        rows: monthRowsData?.may ? monthRowsData.may : []
      },
      {
        title: gettext("June"),
        columns: monthColumns,
        rows: monthRowsData?.jun ? monthRowsData.jun : []
      },
      {
        title: gettext("July"),
        columns: monthColumns,
        rows: monthRowsData?.jul ? monthRowsData.jul : []
      },
      {
        title: gettext("August"),
        columns: monthColumns,
        rows: monthRowsData?.aug ? monthRowsData.aug : []
      },
      {
        title: gettext("September"),
        columns: monthColumns,
        rows: monthRowsData?.sep ? monthRowsData.sep : []
      },
      {
        title: gettext("October"),
        columns: monthColumns,
        rows: monthRowsData?.oct ? monthRowsData.oct : []
      },
      {
        title: gettext("November"),
        columns: monthColumns,
        rows: monthRowsData?.nov ? monthRowsData.nov : []
      },
      {
        title: gettext("December"),
        columns: monthColumns,
        rows: monthRowsData?.dec ? monthRowsData.dec : []
      },
    ];

    setExcelData(excelDataArray);
  }, [customer, gettext, monthColumns, monthRowsData, year, yearColumns, yearRowsData]);


  useEffect(() => {
    let filenamePrefix = "energy-statement-";
    if (isClimate) {
      filenamePrefix = "climate-statement-";
    }
    setFileNameForExport(urlFriendlyString(filenamePrefix + customer + "-" + energyType + "-" + year + "-" + month + "-" + Math.floor(Date.now() / 1000)));
  }, [energyType, year, month, customer, isClimate]);
  //
  // useEffect(() => {
  //   if (metersData.length) {
  //     const chartDateTemp = meterApiDataToBarChartData(metersData, shortSegment);
  //     if (!isEqual(chartDateTemp, chartData)) {
  //       setChartData(meterApiDataToBarChartData(metersData, shortSegment));
  //     }
  //   }
  // }, [chartData, shortSegment]);
  //endregion React Hooks (useEffect)

  //region For Table
  // const columns = [
  //   // {
  //   //   field: "ts",
  //   //   headerName: gettext("Date"),
  //   //   flex: 1,
  //   //   type: "date",
  //   //   valueGetter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = new Date(value);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   },
  //   //   valueFormatter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = moment(value).format(segmentList[segment].dateFormat);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   }
  //   // },
  //   {
  //     field: "installation",
  //     headerName: gettext("Installation"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "measure",
  //     headerName: gettext("measure"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "company",
  //     headerName: gettext("company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "network_company",
  //     headerName: gettext("network_company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "Installation_address",
  //     headerName: gettext("Installation_address"),
  //     // flex: 1,
  //     width: 300,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "house",
  //     headerName: gettext("house"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "post_code",
  //     headerName: gettext("post_code"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "by",
  //     headerName: gettext("by"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "comment",
  //     headerName: gettext("comment"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "net_tarif",
  //     headerName: gettext("net_tarif"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "cvr",
  //     headerName: gettext("cvr"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "annual_consumption",
  //     headerName: gettext("annual_consumption"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sum",
  //     headerName: gettext("sum"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "percent",
  //     headerName: gettext("percent"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jan",
  //     headerName: gettext("Jan 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "feb",
  //     headerName: gettext("Feb 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "mar",
  //     headerName: gettext("Mar 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "apr",
  //     headerName: gettext("Apr 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "may",
  //     headerName: gettext("May 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jun",
  //     headerName: gettext("Jun 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jul",
  //     headerName: gettext("Jul 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "aug",
  //     headerName: gettext("Aug 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sep",
  //     headerName: gettext("Sep 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "oct",
  //     headerName: gettext("Oct 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "nov",
  //     headerName: gettext("Nov 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "dec",
  //     headerName: gettext("Dec 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   // {
  //   //   field: "consumptionConversionRate",
  //   //   headerName: gettext("Conversion Rate (g CO₂/kWh)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(2)
  //   // },
  //   // {
  //   //   field: "consumptionCo2",
  //   //   headerName: gettext("CO₂ (kilogram)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(0)
  //   // }
  //   // {
  //   //   field: "consumptionCost",
  //   //   headerName: gettext("Cost (DKK)"),
  //   //   flex: 1,
  //   //   type: "number"
  //   // }
  // ];

  // const initialState = {
  //   pagination: {
  //     paginationModel: {
  //       page: 0,
  //       pageSize: 50
  //     }
  //   }
  //   // columns: {
  //   //   columnVisibilityModel: {
  //   //     id: false,
  //   //   }
  //   // }
  // };
  //endregion For Table

  // const saveFile = async (blob) => {
  //   const a = document.createElement('a');
  //   a.download = 'my-file.txt';
  //   a.href = URL.createObjectURL(blob);
  //   a.addEventListener('click', (e) => {
  //     setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  //   });
  //   a.click();
  // };

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.selectedYearDate) {
      setYear(selectedValues.selectedYearDate.getFullYear());
    }

    if (selectedValues?.selectedMonth?.value) {
      setMonth(selectedValues.selectedMonth.value);
    }

    if (selectedValues?.consumptionOrCo2?.value) {
      switch (selectedValues?.consumptionOrCo2?.value) {
        case "climate":
        case "co2":
          setIsClimate(true);
          break;
        default:
          setIsClimate(false);
          break;
      }
    }

    //
    // if (selectedValues?.building?.value) {
    //   setBuilding(selectedValues.building.value);
    // }
    //
    // if (selectedValues?.meter?.value) {
    //   setMeter(selectedValues.meter.value);
    // }

    // if (selectedValues?.selectedYearDate) {
    //   setSelectedYearDate(selectedValues.selectedYearDate);
    // }
  };

  // const handleChangeEnergyStatement = (obj) => {
  //   setSelectedEnergyStatement(obj.value);
  // };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        consumptionOrCo2={isClimate ? "climate" : "consumption"}
        showEnergyType
        showConsumptionOrCo2
        showYear
        showMonth
        // showMonth={selectedEnergyStatement === "monthly-statement"}
      />
      <br/>

      {month === "all" && (
        <EnergyOverallStatement
          // onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          // data={data}
          columns={yearColumns}
          rows={yearRowsData}
          excelData={excelData}
          fileNameForExport={fileNameForExport}
          loading={loading || !dataReceived}
          isClimate={isClimate}
        />
      )}

      {month !== "all" && (
        <EnergyMonthlyStatement
          // onChangeEnergyStatement={handleChangeEnergyStatement}
          meterType={energyType}
          year={year}
          month={month}
          data={data}
          columns={monthColumns}
          rows={monthRowsData}
          excelData={excelData}
          fileNameForExport={fileNameForExport}
          loading={loading || !dataReceived}
          isClimate={isClimate}
        />
      )}

      {/*<BarChartBox*/}
      {/*  loading={loading}*/}
      {/*  title={gettext("Consumption measured in kWh")}*/}
      {/*  chartId={"consumption"}*/}
      {/*  data={{*/}
      {/*    dataset: chartData,*/}
      {/*    series: [{*/}
      {/*      dataKey: "consumptionV",*/}
      {/*      label: gettext("Consumption"),*/}
      {/*      color: theme.palette.chart.electricity,*/}
      {/*      unit: gettext("kWh"),*/}
      {/*    }]*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<br/>*/}

      {/*<TableView1*/}
      {/*  title={gettext("Electricity consumption")}*/}
      {/*  rows={metersData}*/}
      {/*  // rows={[]}*/}
      {/*  columns={columns}*/}
      {/*  loading={loading}*/}
      {/*  checkboxSelection={false}*/}
      {/*  initialState={initialState}*/}
      {/*  showGridToolbarDeleteButton={false}*/}
      {/*  showGridToolbarDensitySelector={false}*/}
      {/*  showGridToolbarFilterButton={false}*/}
      {/*  showGridToolbarQuickFilter={true}*/}
      {/*  showGridToolbarColumnsButton={false}*/}
      {/*  fileNameForExport={fileNameForExport}*/}
      {/*/>*/}
    </>
  );
};