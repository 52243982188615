import React, {useContext, useState} from "react";
import styled from "@mui/styled-engine";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import {Menu} from "./Menu";
import {IconButton, Link, SwipeableDrawer, Typography} from "@mui/material";
import {ChevronBack} from "comundo-icons";
import {PartnerImagePath} from "../../../../../hooks";
import {useTextDomainContext} from "../../../../providers";
import {getPartnerKey} from "../../../../../utils";
// import {Image} from "react-bootstrap";

const DrawerHeader = styled("div")(({theme}) => ({
  // display: "flex",
  // flexDirection: "row",
  // justifyContent: "space-between",
  // alignItems: "center",
  // padding: theme.spacing(0, 1),
  // ...theme.mixins.toolbar
}));

const DrawerMenu = styled(Box)(({theme}) => ({
  display: "flex",
  flex: 1,
  // flexDirection: "column", // Stack content vertically
  // justifyContent: "flex-end", // Align content at the bottom
  // alignItems: "center",
  // padding: theme.spacing(1), // Add some padding for spacing
  // flexGrow: 0, // Prevent footer from expanding to fill available space
  // backgroundColor: "red"
}));

const DrawerFooter = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column", // Stack content vertically
  justifyContent: "flex-end", // Align content at the bottom
  alignItems: "center",
  padding: theme.spacing(1), // Add some padding for spacing
  flexGrow: 0, // Prevent footer from expanding to fill available space
  backgroundColor: "transparent"
}));

const LogoImage = styled("img")(({theme}) => ({
  // "WebkitFilter": "drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 #999999)",
  // "filter": "drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 #999999)",
  "width": "fit-content",
  "height": "fit-content",
  "maxHeight": "100%",
  "maxWidth": "100%"
}));

/**
 *
 * @param root0
 * @param root0.drawerWidth
 * @param root0.isDrawerOpen
 * @param root0.setIsDrawerOpen
 * @param root0.children
 */
export function SideDrawer({
  drawerWidth,
  isDrawerOpen,
  setIsDrawerOpen,
  children
}) {
  // const {user} = users();
  // const partnerKey = user?.partner?.key;
  const partnerKey = getPartnerKey();
  const [logo, setLogo] = useState();
  // const theme = useTheme();
  // const primaryDark = theme.palette.primary[900];
  // const primaryLight = theme.palette.primary[600];
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  PartnerImagePath(partnerKey, "logos/logo-light.png").then(imageUrl => {
    setLogo(imageUrl);
  });

  // LogoImage(partnerKey, "dark").then(imageUrl => {
  //   setLogo(imageUrl);
  // });

  return (
    <>
      <Box
        component="nav"
        sx={{
          width: {
            sm: drawerWidth
          },
          flexShrink: {
            sm: 0
          }
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            display: {
              xs: "none",
              sm: "flex"
            },
            // height: `calc(100vh - 150px )`,
            // maxWidth: "100%",
            // flexWrap: "wrap",
            // alignContent: "space-between",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main",
              // background: `linear-gradient(to bottom, ${primaryDark}, ${primaryLight})`,
              // scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "8px",
                // backgroundColor: "primary.700",
                // backgroundColor: "primary.main",
                // background: `linear-gradient(to bottom, ${primaryDark}, ${primaryLight})`,
                // backgroundColor: primaryLight // Optional: Set background color for the scrollbar track
              },
              "&::-webkit-scrollbar-thumb": {
                // backgroundColor: "rgba(0, 255, 0, 0.3)", // Set background color for the scrollbar thumb
                // backgroundColor: primaryDark, // Set background color for the scrollbar thumb
                backgroundColor: "primary.700",
                // background: `linear-gradient(to bottom, ${primaryDark}, ${primaryLight})`,
                borderRadius: "8px" // Optional: Add border radius to the thumb
              }
            }

          }}
          open
        >
          <DrawerHeader>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "10px 20px",
              height: "75px"
              // position: "relative"
            }}>
              {/* <Link to="" style={{ */}
              {/*   position: "absolute", */}
              {/*   width: "90%", */}
              {/*   maxHeight: "100%" */}
              {/* }}> */}
              {/* <LogoImage alt="logo" src={"/media/logos/energiteam-logo.png"} /> */}
              {/* <img alt="logo" src={"/media/logos/energiteam-logo.png"} /> */}
              {/* <img alt="logo" src={logoImage(partnerKey, "dark")} /> */}
              {logo && <LogoImage alt="logo" src={logo}/>}
              {/* </Link> */}
            </Box>
          </DrawerHeader>
          <DrawerMenu>
            <Menu/>
          </DrawerMenu>
          <DrawerFooter>
            <Box sx={{
              maxWidth: "100%",
              width: "185px",
            }}>
              <img src="/media/power-stars.png" alt="Stars" height={20}/>
            </Box>
            <Box sx={{
              fontSize: "1rem",
              color: "#FFFFFF"
            }}>
              <Typography variant="span" sx={{
              }}>
                {gettext("Data Delivered By")}
              </Typography>&nbsp;
              <Typography variant="span" sx={{
                fontWeight: 700,
              }}>
                <Link href="https://fabeke.com" target="_blank" color="#FFFFFF" sx={{
                  textDecoration: "none"
                }}>
                  {gettext("Fabeke")}
                </Link>
              </Typography>
            </Box>
          </DrawerFooter>
        </Drawer>

        <SwipeableDrawer
          anchor={"left"}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onOpen={() => setIsDrawerOpen(true)}
          sx={{
            display: {
              xs: "block",
              sm: "none"
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main"
            }
          }}
        >
          <DrawerHeader>
            <Box sx={{
            }}>
              <Link to="">
                {logo && <LogoImage alt="logo" src={logo}/>}
                {/* <img alt="logo" src={"/media/logos/energiteam-logo.png-4"} /> */}
              </Link>
            </Box>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <ChevronBack color={"white"}/>
            </IconButton>
          </DrawerHeader>

          <Menu/>
        </SwipeableDrawer>
      </Box>

      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerWidth}px)`
        }}
      >
        <Toolbar/>
        {children}
      </Box>
    </>
  );
}
