import React, {Fragment, useEffect, useState} from "react";
import {TableBox} from "../";
import PropTypes from "prop-types";
import {Container, Typography, useTheme} from "@mui/material";
// import { BarChartSettings } from "./settings";
import {BarChartLoading} from "./components";
import {BarChart} from "../D3Charts";
import "./styles/BarChartStyle.css";
import {isEqual} from "lodash";

export const BarChartBox = (props) => {
  // const chartSetting = BarChartSettings;
  const theme = useTheme();

  const propsDefault = {
    title: "",
    date: {
      dataset: [],
      // xDataKey: "",
      // yLabel: "",
      series: [{
        dataKey: "",
        label: "",
        color: ""
      }]
    },
    dataKey: "",
  };

  //region React Hooks (useState)
  const [title, setTitle] = useState(typeof props.title === "undefined" ? propsDefault.title : props.title);
  const [data, setData] = useState(propsDefault.data);
  const [loading, setLoading] = useState(false);
  //endregion React Hooks (useState)

  //region React Hooks (useEffect)
  useEffect(() => {
    if (typeof props.title !== "undefined") {
      setTitle(props.title);
    }
  }, [props.title]);

  useEffect(() => {
    if (typeof props.loading !== "undefined") {
      setLoading(props.loading);
    }
  }, [props.loading]);

  useEffect(() => {
    if (typeof props.data !== "undefined" && !isEqual(props.data, data)) {
      setData(props.data);
    }
  }, [props.data, data]);

  //endregion React Hooks (useEffect)

  return (
    <TableBox sx={{
      minHeight: "350px",
      padding: "24px",
      position: "relative",
    }}>
      <Typography variant="h4">
        {title}
      </Typography>
      <Container sx={{
        position: "absolute",
        top: 25,
        right: 25,
        display: "flex",
        justifyContent: "flex-end",
        // width: 200,
        alignItems: "center",
        gap: 2,
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        {props.data.series.map((obj, index) => {
          return (
            <Fragment key={`span-${index}`}>
              <span style={{
                height: 12,
                width: 12,
                backgroundColor: obj.color,
                borderRadius: "100%"
              }}/>
              <Typography sx={{
                textTransform: "capitalize"
              }} variant="p">
                {obj.label}
              </Typography>
            </Fragment>
          );
        })}

      </Container>
      {loading ? (
        <BarChartLoading/>
      ) : (
        // <BarChart
        //   dataset={data.dataset}
        //   xAxis={[{
        //     scaleType: "band",
        //     dataKey: data.xDataKey
        //   }]}
        //   yAxis={[
        //     {
        //       label: data.yLabel
        //     }
        //   ]}
        //   series={data.series}
        //   slotProps={{
        //     // legend: {
        //     //   direction: "row",
        //     //   position: {
        //     //     vertical: "top",
        //     //     horizontal: "left"
        //     //   },
        //     //   padding: 0
        //     // }
        //   }}
        //   {...chartSetting}
        // />
        <BarChart
          {...props}
          // prefix={props.energyType && Constants[props.energyType].prefixes[props.dataKey]}
          // color={props.energyType && Constants[props.energyType].color}
          color={props?.data?.series[0]?.color ? props.data.series[0].color : theme.palette.primary}
          // data={data?.dataset}
          height={250}
          // dataKey={props.dataKey}
        />
      )}

    </TableBox>
  );
};

BarChartBox.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.object
  // data: PropTypes.objectOf({
  //   dataset: PropTypes.arrayOf(PropTypes.object),
  //   xDataKey: PropTypes.string,
  //   yLabel: PropTypes.string,
  //   series: PropTypes.arrayOf({
  //     dataKey: PropTypes.string,
  //     label: PropTypes.string,
  //     color: PropTypes.string
  //   })
  // })
};
