/**
 *
 * @param columns array
 * @param rows array
 * @return Array
 * @constructor
 */
export const gridDataToExportArray = (columns, rows) => {
  const finalArray = [];
  rows.forEach((row) => {
    const finalRow = {
    };
    columns.forEach((column) => {
      switch (column.headerName) {
        case "Actions":
        case "Action":
        case "ID":
          // Dont add
          break;
        default:
          finalRow[column.headerName] = row[column.field];
          break;
      }

    });
    finalArray.push(finalRow);
  });
  return finalArray;
};
