import React from "react";
import PropTypes from "prop-types";
import {SubsidiaryDropdownCommon} from "./SubsidiaryDropdownCommon";

/**
 * Subsidiary dropdown component
 * @param {object} props props
 * @returns {Element} React Component
 * @class
 */
export const SubsidiaryDropdown = (props) => {
  return (
    <SubsidiaryDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      currentTeam={props.currentTeam}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="old"
    />
  );
};

SubsidiaryDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
