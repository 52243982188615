import React, {useEffect} from "react";
import {useAuth, useUser} from "app/providers";
import {Navigate, Outlet} from "react-router";
import {useMe} from "../../../hooks";
import {LayoutSplashScreen} from "../../components";

// import { LayoutSplashScreen } from "_metronic/layout";

/**
 *
 */
export function AcquireLogin() {
  useMe();
  // const {TextDomainContext} = useTextDomainContext();
  // const {gettext} = useContext(TextDomainContext);
  const {isAuthenticated} = useAuth();
  const {user} = useUser();

  // const navigate = useNavigate();
  // const { pathname } = useLocation();

  // const [retry, setRetry] = useState(true);

  // const user = null;

  // const { isRegistered, teams } = user || {
  // };

  // useEffect(() => {
  //   if (isRegistered !== undefined) {
  //     if (!isRegistered || !teams.length) {
  //       navigate("/registration-flow");
  //     } else navigate(pathname);
  //   }
  // }, [isRegistered, navigate, pathname, teams]);

  // useEffect(() => {
  //   if (retry) {
  //     setTimeout(() => {
  //       console.log(isAuthenticated, user);
  //       if (isAuthenticated && !user) {
  //         window.location.replace(window.location.href);
  //         setRetry(false);
  //       }
  //       if (isAuthenticated && user) {
  //         setRetry(false);
  //       }
  //     }, 20000);
  //   }
  // }, [isAuthenticated, retry, user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAuthenticated && !user) {
        window.location.reload();
      }
    }, 5000);

    // Clear interval when user is defined or authentication fails
    return () => clearInterval(intervalId);
  }, [isAuthenticated, user]);

  return isAuthenticated ? (
    user ? (
      <Outlet/>
    ) : (<>
      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  justifyContent="center"*/}
      {/*  alignItems="center"*/}
      {/*  minHeight="100vh"*/}
      {/*  textAlign="center"*/}
      {/*>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="h6" gutterBottom>*/}
      {/*      {gettext("Please wait while we are verifying your credentials.")}*/}
      {/*    </Typography>*/}

      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      color="primary"*/}
      {/*      onClick={() => window.location.replace(window.location.href)}*/}
      {/*      sx={{*/}
      {/*        mb: 2*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {gettext("Retry")}*/}
      {/*    </Button>*/}

      {/*    /!*<Typography variant="body1" gutterBottom>*!/*/}
      {/*    /!*  {gettext("or")}*!/*/}
      {/*    /!*</Typography>*!/*/}

      {/*    /!*<Button variant="outlined" color="secondary" href="/logout">*!/*/}
      {/*    /!*  {gettext("Login again")}*!/*/}
      {/*    /!*</Button>*!/*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      <LayoutSplashScreen/>
    </>
    )
  ) : (
    <>
      <Navigate to="/auth/login"/>
    </>
  );
}
