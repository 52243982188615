import {deleteAll, deleteById, getById, update} from '../../common/IndexedDb';

class IndexedDBWrapper {
  constructor(storeName) {
    this.storeName = storeName;
  }

  async getItem(key) {
    try {
      const result = await getById(this.storeName, key);
      return result ? result.value : null;
    } catch (error) {
      // console.error(`Error getting item from ${this.storeName}:`, error);
      return null;
    }
  }

  async setItem(key, value) {
    try {
      const data = {
        key,
        value
      };
      await update(this.storeName, data, key);
    } catch (error) {
      console.error(`Error setting item in ${this.storeName}:`, error);
    }
  }

  async removeItem(key) {
    try {
      await deleteById(this.storeName, key);
    } catch (error) {
      console.error(`Error removing item from ${this.storeName}:`, error);
    }
  }

  async clear() {
    try {
      await deleteAll([this.storeName]);
    } catch (error) {
      console.error(`Error clearing store ${this.storeName}:`, error);
    }
  }
}

export default IndexedDBWrapper;
