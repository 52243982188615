import md5 from "md5";
import {getById} from "../../app/common/IndexedDb";

export const getCacheWithExpiry = key => {
  return new Promise((resolve, reject) => {
    const now = new Date();
    // const [data, changeData] = useState(null)
    key = md5(key);
    getById("cache", key)
      .then(row => {
        if (row.value) {
          if (now.getTime() > row.expiry) {
            // deleteById('cache', key);
            resolve(null);
          }
          resolve(row.value);
        } else {
          reject(row);
        }
      })
      .catch(err => {
        //
        resolve(null);
      });
  });
};