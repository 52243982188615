import {TableView1} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import * as PropsType from "prop-types";

export const EnergyOverallStatement = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const propsDefault = {
    title: gettext("Annual energy consumption (kWh)")
  };
  const [fileNameForExport, setFileNameForExport] = useState("energy-overall-statement");
  const [title, setTitle] = useState(propsDefault.title);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFileNameForExport(props.fileNameForExport);
  }, [props.fileNameForExport]);

  useEffect(() => {
    if (props.isClimate) {
      setTitle(gettext("Annual CO₂e (Kg)"));
    } else {
      setTitle(propsDefault.title);
    }
  }, [gettext, props.isClimate, propsDefault.title]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  // useEffect(() => {
  //   if (data) {
  //     const columnsData = energyOverallColumns(data, gettext, locale, props.year);
  //     const rowsData = energyOverallRows(data, props.year);
  //     if (columnsData.length) {
  //       setColumns(columnsData);
  //     } else {
  //       setColumns([]);
  //     }
  //     if (rowsData.length) {
  //       setRows(rowsData);
  //     } else {
  //       setRows([]);
  //     }
  //   }
  // }, [data, gettext, locale, props.year]);
  // const columns = [
  //   {
  //     field: "meter",
  //     headerName: gettext("Order No"),
  //     width: 200,
  //   },
  //   {
  //     field: "meterNumber",
  //     headerName: gettext("Meter No"),
  //     width: 150,
  //   },
  //   {
  //     field: "customer",
  //     headerName: gettext("Company"),
  //     width: 250,
  //   },
  //   // {
  //   //   field: "address",
  //   //   headerName: gettext("Address"),
  //   //   width: 300,
  //   // },
  //   {
  //     field: "streetName",
  //     headerName: gettext("Street"),
  //     width: 200,
  //   },
  //   {
  //     field: "buildingNumber",
  //     headerName: gettext("House No."),
  //     width: 100,
  //   },
  //   {
  //     field: "postcode",
  //     headerName: gettext("Zip Code"),
  //     width: 100,
  //   },
  //   {
  //     field: "cityName",
  //     headerName: gettext("City"),
  //     width: 150,
  //   },
  //   {
  //     field: "customerCVR",
  //     headerName: gettext("Company No."),
  //     width: 100,
  //   },
  //
  //   {
  //     field: "total",
  //     headerName: gettext("Sum"),
  //     // flex: 1,
  //     width: 150,
  //     type: "number",
  //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "jan",
  //     headerName: gettext("January"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "feb",
  //     headerName: gettext("February"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => toLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "mar",
  //     headerName: gettext("March"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "apr",
  //     headerName: gettext("April"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "may",
  //     headerName: gettext("May"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "jun",
  //     headerName: gettext("June"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "jul",
  //     headerName: gettext("July"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "aug",
  //     headerName: gettext("August"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "sep",
  //     headerName: gettext("September"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "oct",
  //     headerName: gettext("October"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "nov",
  //     headerName: gettext("November"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  //   {
  //     field: "dec",
  //     headerName: gettext("December"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     valueFormatter: (value) => ToLocaleNumber(locale, value, 0),
  //   },
  // ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };

  const handleChangeEnergyStatement = (obj) => {
    if (typeof props.onChangeEnergyStatement === "function") {
      props.onChangeEnergyStatement(obj);
    }
  };

  return (
    <>
      {/*{props.rows.length > 0 && (*/}
      <TableView1
        title={title}
        rows={props.rows}
        // rows={[]}
        columns={props.columns}
        loading={loading}
        initialState={initialState}
        showGridToolbar
        // showEnergyStatementType
        showGridToolbarEnergyStatementExport
        showGridToolbarQuickFilter
        fileNameForExport={fileNameForExport}
        excelData={props.excelData}
        onChangeEnergyStatement={handleChangeEnergyStatement}
        // selectedEnergyStatement="overall-statement"
      />
      {/* )} */}
      {/*{loading && (*/}
      {/*  TableLoadingStateEnergyStatement()*/}
      {/*  // gettext("Loading")*/}
      {/*)}*/}
      {/*{!loading && !props.rows && (*/}
      {/*  TableLoadingStateEnergyStatement()*/}
      {/*  // gettext("Still Loading")*/}
      {/*)}*/}
      {/*{!loading && props?.rows && props?.rows?.length === 0 && (*/}
      {/*  <>*/}
      {/*    {gettext("No data found")}<br/>*/}
      {/*    {gettext("If the data is heavy, it may still be loading.")}<br/>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

EnergyOverallStatement.propTypes = {
  onChangeEnergyStatement: PropsType.func,
  fileNameForExport: PropsType.string,
  excelData: PropsType.array,
};