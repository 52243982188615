import {useQuery} from "@apollo/client";
import {GET_GROUPS} from "./commands";
import "../../app/types/CustomersType";

/**
 *
 * @param customerName string
 */
export const useGetGroups = ((customerName, customerId) => {
  let returnData = [];
  const loadingCustomers = false;
  // const {
  //   customers,
  //   loading: loadingCustomers
  // } = useCompanies({
  //   customerId
  // });

  const {
    /**
     * @typedef {Object} DataType
     * @property {CustomersType[]} getGroups - An array of group objects.
     */
    data,
    loading
  } = useQuery(GET_GROUPS, {
    variables: {
      customerName
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  if (data?.getGroups) {
    // if (data?.getGroups && !loadingCustomers) {
    // let cvr = "";
    // if (customers.length) {
    //   cvr = customers[0].cvr;
    // }
    // returnData = sortCustomers(modifyCustomerArray(data?.getGroups, cvr, customerId));
    returnData = data?.getGroups;
  }

  return {
    /** @type {CustomersType[]} */
    subsidiary: returnData,
    loading: loading || loadingCustomers,
  };
});

// const modifyCustomerArray = (data, cvr, id) => {
//   return data.map((customer) => {
//     if (customer.cvr === cvr && customer.id !== id) {
//       // Remove customer if cvr matches and id doesn't
//       return null;
//     } else if (customer.id === id) {
//       // Change name to "All" if id matches
//       return {
//         ...customer, nameAlias: "All"
//       };
//     } else {
//       // Keep the customer as it is for any other case
//       return {
//         ...customer, nameAlias: customer.name
//       };
//     }
//   }).filter(customer => customer !== null); // Remove null values after mapping
// };
//
// const sortCustomers = (data) => {
//   return [...data].sort((a, b) => {
//     if (a.nameAlias === "All") return -1; // "All" goes first
//     if (b.nameAlias === "All") return 1; // Anything else comes after "All"
//     return a.name.localeCompare(b.nameAlias); // Regular alphabetical sort
//   });
// };