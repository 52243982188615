import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import "./Styles/BarChart.css";
import {toLocaleNumber} from "../../../utils";
import {useLang} from "../../providers";

export const BarChart = (props) => {
  const locale = useLang();

  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);

  const svgRef = useRef();
  const maxWidth = 20;
  const innerPadding = 10;

  useEffect(() => {
    if (props?.data?.dataset) {
      setData(props?.data?.dataset);
    }
  }, [props?.data?.dataset]);

  useEffect(() => {
    if (props?.data?.series) {
      setSeries(props?.data?.series);
    }
  }, [props?.data?.series]);

  useEffect(() => {
    if (!svgRef.current) return;
    const keyColors = {
    };
    const keyLabels = {
    };
    const keyUnits = {
    };
    const colors = [];
    const fields = [];

    series.map((obj) => {
      fields.push(obj.dataKey);
      if (obj.color) {
        keyColors[obj.dataKey] = obj.color;
        colors.push(obj.color);
      } else {
        keyColors[obj.dataKey] = "#000";
        colors.push("#000");
      }
      if (obj.label) {
        keyLabels[obj.dataKey] = obj.label;
      } else {
        keyLabels[obj.dataKey] = "";
      }
      if (obj.unit) {
        keyUnits[obj.dataKey] = ` (${obj.unit})`;
      } else {
        keyUnits[obj.dataKey] = "";
      }
      return null;
    });

    // const colors = series.map((obj) => obj.color);
    // const fields = series.map((obj) => obj.dataKey);

    d3.select(svgRef.current).selectAll('*').remove();

    // Remove any existing tooltips to avoid duplication
    d3.select(`#tooltip-${props.chartId}`).remove();

    // Create tooltip element within the chart container
    // const tooltip = d3.select(svgRef.current.parentNode)
    const tooltip = d3.select("body")
      .append('div')
      .attr('id', `tooltip-${props.chartId}`)
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    // Set up chart dimensions
    const margin = {
      top: 20,
      right: 30,
      bottom: 40,
      left: 80, // y-axis labels space
    };
    const width = svgRef.current.clientWidth - margin.left - margin.right + 2 * innerPadding;
    const height = 300 - margin.top - margin.bottom;

    // Create SVG element
    const svg = d3.select(svgRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Set up scales
    const x0 = d3.scaleBand()
      .domain(data.map(d => d.label))
      // .rangeRound([0, width])
      .rangeRound([innerPadding, width - innerPadding])
      .paddingInner(0.01);

    const x1 = d3.scaleBand()
      .domain(fields)
      .rangeRound([0, Math.min(x0.bandwidth(), maxWidth * fields.length)])
      .padding(0.05);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => Math.max(...fields.map(field => d[field] || 0)) + 0.01)])
      .nice()
      .range([height, 0]);

    const color = d3.scaleOrdinal()
      .domain(fields)
      .range(colors);

    // Add horizontal grid lines
    svg.append('g')
      .attr('class', 'grid')
      .attr("color", "#e5e7eb")
      .call(d3.axisLeft(y)
        .tickSize(-width)
        .tickFormat(''));

    // Add axes
    svg.append('g')
      .selectAll('g')
      .data(data)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${x0(d.label)},0)`)
      .selectAll('rect')
      .data(d => fields.map(key => ({
        key,
        value: d[key] || 0,
        xLabel: d.label,
        series
      })))
      .enter()
      .append('rect')
      .attr('x', d => {
        const bandwidth = Math.min(x1.bandwidth(), maxWidth);
        return (x0.bandwidth() - (bandwidth * fields.length)) / 2 + x1(d.key);
      })
      .attr('y', d => y(d.value))
      .attr('width', Math.min(x1.bandwidth(), maxWidth))
      .attr('height', d => height - y(d.value))
      .attr('fill', d => color(d.key))
      .on('mouseover', (event, d) => {

        const tooltipWidth = 200;
        // const tooltipHeight = 100;
        // Get the position of the SVG container relative to the page
        // const svgRect = svgRef.current.getBoundingClientRect();
        const mouseX = event.pageX;
        const mouseY = event.pageY;
        const tooltipX = mouseX + 15;
        const tooltipY = mouseY - 28;

        tooltip.transition()
          .duration(200)
          .style('opacity', 1);

        tooltip.html(`
          <div style="padding: 0; margin: 0">
            <div style="text-align: left; font-weight: bold; padding: 10px;">
              ${d.xLabel}
            </div>
            <div style="border-bottom: 1px solid #ccc"></div>
            <div style="text-align: left; padding: 10px;">
              <div style="float: left; height: 12px; width: 12px; margin-right: 10px; background-color:${keyColors[d.key]}; border-radius: 100%;"></div>
              ${keyLabels[d.key]}: ${toLocaleNumber(locale, d.value || 0)}${keyUnits[d.key]}
              <!-- ${keyLabels[d.key]}: <span style="color:${keyColors[d.key]}">${parseFloat(d.value).toFixed(2)}${keyUnits[d.key]}</span> -->
            </div>
          </div>
        `)
          .style('left', `${Math.min(tooltipX, window.innerWidth - tooltipWidth)}px`)
          .style('top', `${Math.min(tooltipY)}px`);
        // .style('top', `${Math.min(tooltipY, window.innerHeight - tooltipHeight)}px`);
      })
      .on('mouseout', () => {
        tooltip.transition()
          .duration(500)
          .style('opacity', 0);
      });

    svg.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x0).tickFormat((d, i) => {
        // Adjust label format to avoid overlap
        const labelLength = d.length;
        const chartWidth = svgRef.current.clientWidth - 100;
        const extraMarginCharacters = data.length * 2;
        const totalCharacters = data.length * labelLength;
        const pixelPerCharacter = chartWidth / totalCharacters;
        const oneLabelPixels = pixelPerCharacter * labelLength;
        const maxLabels = Math.ceil(chartWidth / (oneLabelPixels + extraMarginCharacters)) - 1;

        return (data.length > 12 && i % Math.ceil(data.length / maxLabels) !== 0) ? '' : d;
      }));
    // .selectAll('text')
    // .attr('transform', 'rotate(-45)')
    // .style('text-anchor', 'end');

    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y));

  }, [data, locale, props.chartId, series]);

  return (
    <div style={{
      position: 'relative'
    }}>
      <svg
        ref={svgRef}
        style={{
          width: '100%'
        }}
      ></svg>
    </div>
  );
};