import React, {useContext, useEffect, useState} from "react";
import {useLang, useTextDomainContext, useUser} from "app/providers";
import {useClimateData} from "../../../hooks";
import {BarChartBox, FilterBar1, TableView1} from "../../components";
import moment from "moment";
import {formatDateForAPI,
  meterApiDataToBarChartData,
  segmentList,
  toLocaleNumber,
  urlFriendlyString} from "../../../utils";
import {useTheme} from "@mui/material";
import {isEqual} from "lodash";

/**
 *
 * @param props
 */
export const Carbon = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const segments = segmentList();
  let {
    currentTeam: customer,
    currentSubsidiary: group,
  } = user || {
  };
  const locale = useLang();
  const theme = useTheme();
  if (!customer) {
    customer = "all";
  }
  if (!group) {
    group = "All";
  }

  //region React Hooks (useState)
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("all");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [segment, setSegment] = useState("year");
  const [shortSegment, setShortSegment] = useState("m");
  const [chartData, setChartData] = useState([]);
  const [fileNameForExport, setFileNameForExport] = useState("carbon-emissions-export");
  //endregion  React Hooks (useState)

  //region Data
  const filters = {
    customer,
    group,
    address: building,
    meteringPointId: meter,
    meterType: energyType,
    fromDate: formatDateForAPI(fromDate),
    toDate: formatDateForAPI(toDate),
    resolution: shortSegment,
    fileType: "csv"
  };
  const {
    data: metersData,
    loading
  } = useClimateData(filters);
  //endregion Data

  //region React Hooks (useEffect)
  useEffect(() => {
    setFileNameForExport(urlFriendlyString("carbon-emissions-" + building + "-" + meter + "-" + energyType + "-" + segment + "-" + fromDate + "-" + toDate));
  }, [building, energyType, fromDate, meter, segment, toDate]);

  useEffect(() => {
    if (metersData) {
      const chartDateTemp = meterApiDataToBarChartData(metersData, shortSegment);
      if (!isEqual(chartDateTemp, chartData)) {
        setChartData(meterApiDataToBarChartData(metersData, shortSegment));
      }
    }
  }, [chartData, metersData, shortSegment]);
  //endregion React Hooks (useEffect)

  //region For Table
  const columns = [
    {
      field: "ts",
      headerName: gettext("Date"),
      flex: 1,
      type: "date",
      valueGetter: (value) => {
        let returnValue = "";
        if (value) {
          if (value === "Summary") {
            returnValue = value;
          } else {
            returnValue = new Date(value);
          }
        }
        return returnValue;
      },
      valueFormatter: (value) => {
        let returnValue = "";
        if (value) {
          if (value === "Summary") {
            returnValue = value;
          } else {
            returnValue = moment(value).format(segments[segment].dateFormat);
          }
        }
        return returnValue;
      }
    },
    {
      field: "consumptionV",
      headerName: gettext("Consumption (kWh)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "conversionRate",
      headerName: gettext("Conversion Rate (g CO₂e/kWh)"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => value && value.toFixed(2)
    },
    {
      field: "co2",
      headerName: gettext("Kg of CO₂e"),
      flex: 1,
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 0)
    }
    // {
    //   field: "consumptionCost",
    //   headerName: gettext("Cost (DKK)"),
    //   flex: 1,
    //   type: "number"
    // }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };
  //endregion For Table

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }

    if (selectedValues?.segment) {
      setSegment(selectedValues.segment);
      setShortSegment(segments[selectedValues.segment].shortSegment);
      if (selectedValues.segment === "day" && moment(selectedValues?.date?.startDate).format("YYYY-MM-DD") !== moment(selectedValues?.date?.endDate).format("YYYY-MM-DD")) {
        setSegment("month");
        setShortSegment(segments["month"].shortSegment);
      }
    }

  };

  return (
    <>
      <FilterBar1
        getSelectedValues={handleGetSelectedValues}
        showBuilding
        showMeter
        showEnergyType
        showDate
        showBuildingAll
        showMeterAll
        // showEnergyTypeAll
      />
      <br/>

      <BarChartBox
        loading={loading}
        title={gettext("Kg of CO₂e")}
        chartId={"co2"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "co2",
            label: gettext("Consumption"),
            color: theme.palette.chart.electricity,
            unit: gettext("Kilogram")
          }]
        }}
      />
      <br/>

      <BarChartBox
        loading={loading}
        title={gettext("Conversion rate in g CO₂e/kWh")}
        chartId={"conversion"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "conversionRate",
            label: gettext("Consumption"),
            color: theme.palette.chart.electricity,
            unit: gettext("g CO₂/kW"),
          }]
        }}
      />
      <br/>

      <BarChartBox
        loading={loading}
        title={gettext("Consumption measured in kWh")}
        chartId={"consumption"}
        data={{
          dataset: chartData,
          series: [{
            dataKey: "consumptionV",
            label: gettext("Consumption"),
            color: theme.palette.chart.electricity,
            unit: gettext("kWh"),
          }]
        }}
      />
      <br/>

      <TableView1
        title={gettext("Electricity consumption and CO₂e")}
        rows={metersData}
        columns={columns}
        loading={loading}
        initialState={initialState}
        showGridToolbar
        showGridToolbarExport
        fileNameForExport={fileNameForExport}
      />
    </>
  );
};
