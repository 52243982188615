import React, {createContext, useCallback, useContext} from "react";
import {useCookies} from "react-cookie";
// import {useSendLoginSlackMessage} from "../../../hooks";
import {setCacheWithExpiry} from "../../../utils";

const AuthContext = createContext();

const AUTH_TOKEN_KEY = "userAuthToken";

/**
 *
 */
export function useAuth() {
  const {
    AuthToken,
    setToken,
    unsetToken
  } = useContext(AuthContext);

  return {
    isAuthenticated: Boolean(AuthToken),
    token: AuthToken,
    setToken,
    unsetToken
  };
}

/**
 *
 * @param root0
 * @param root0.children
 */
export default function AuthProvider({children}) {
  const [cookies, setCookie] = useCookies();

  const AuthToken = cookies[AUTH_TOKEN_KEY];

  // const sendLoginMessage = useSendLoginSlackMessage();

  const setToken = useCallback(
    token => {
      try {
        setCookie(AUTH_TOKEN_KEY, token, {
          path: "/"
        });
        // await sleep(30000);
        setCacheWithExpiry("login-slack-message-sent", 0, 3600 * 24 * 30);
      } catch (error) { /* empty */
      }
    },
    [setCookie]
  );

  const unsetToken = useCallback(() => {
    try {
      setCookie(AUTH_TOKEN_KEY, "", {
        maxAge: -1,
        path: "/"
      });
      // await sleep(5000);
      // graphqlClient.resetStore();
    } catch (error) { /* empty */
    }
  }, [setCookie]);

  const value = {
    AuthToken,
    setToken,
    unsetToken
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
