import {Autocomplete, FormControl, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";

export const AutocompleteDropdown = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);

  const [options, setOptions] = useState([
    {
      value: "",
      label: gettext("Select")
    }
  ]);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="size-small-outlined"
        size="small"
        options={options}
        fullWidth
        // getOptionLabel={(option) => option.label}
        // getOptionKey={(option) => option.value}
        // defaultValue={companies.find(option => option.value === customerValue) || null}
        // value={companies.find(option => option.value === customerValue) || null}
        disableClearable
        // onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label="Companies" placeholder="Companies"/>
        )}
      />
    </FormControl>
  );
};

AutocompleteDropdown.propTypes = {
  // componentLabel: PropTypes.string,
  // defaultValue: PropTypes.string,
  // onChange: PropTypes.func,
};