import React, {useContext, useEffect, useState} from "react";
import {FabekeDropdown} from "./FabekeDropdown";
import {useTextDomainContext, useUser} from "../../providers";
import PropTypes from "prop-types";
import {useGetGroups, useUpdateProfile} from "../../../hooks";
import {isEqual} from "lodash";

// const codesAllCompanies = ["all-companies-full", "all-Companies-readonly", "everything-full", "everything-readonly", "developer"];

/**
 * Customer dropdown component
 * @param {object} props props
 * @returns {Element} React Component
 * @class
 */
export const SubsidiaryDropdownCommon = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const {updateProfile} = useUpdateProfile();
  const {
    currentSubsidiaryId,
    currentSubsidiary,
    currentTeam,
    currentTeamId
  } = user || {
  };
  // const {companiesList} = useGetUserCompanies();
  const {subsidiary} = useGetGroups(currentTeam, currentTeamId);
  // const allowAllCompanies = useHasPermission(codesAllCompanies);

  const propsDefault = {
    defaultValue: currentSubsidiaryId,
    showAll: false,
    componentLabel: ""
  };

  //region UseState [React Hooks]
  const [customerValue, setCustomerValue] = useState(props.defaultValue ? props.defaultValue : propsDefault.defaultValue);
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showAll, setShowAll] = useState(props.showAll ? props.showAll : propsDefault.showAll);
  //endregion UseState

  //region UseEffect [React Hooks]
  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (!currentSubsidiaryId && selectedCompanies.length) {
      updateProfile({
        currentSubsidiary: selectedCompanies[0].name,
        currentSubsidiaryId: selectedCompanies[0].id
      });
    }
  }, [currentSubsidiaryId, selectedCompanies, updateProfile]);

  useEffect(() => {
    if (subsidiary?.length) {
      if (!isEqual(subsidiary, selectedCompanies)) {
        setSelectedCompanies(subsidiary);
      }
    }
  }, [selectedCompanies, subsidiary]);

  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      if (!propsDefault.defaultValue && companies?.length) {
        setCustomerValue(companies[0].value);
      } else {
        setCustomerValue(propsDefault.defaultValue);
      }
    } else {
      setCustomerValue(props.defaultValue);
    }
  }, [companies, props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    const customerArray = [];
    let selectedCustomerId = "";
    if (showAll) {
      customerArray.push({
        label: gettext("All"),
        value: "all"
      });
    }
    selectedCompanies.map(customerRow => {
      if (customerRow.name === currentSubsidiary) {
        selectedCustomerId = customerRow.id;
      }
      const oneCustomer = {
        label: customerRow.name === "All" ? gettext("All") : customerRow.name,
        value: customerRow.id
      };
      customerArray.push(oneCustomer);
      return true;
    });
    if (!isEqual(customerArray, companies)) {
      setCompanies(customerArray);
    }

    if (propsDefault.defaultValue) {
      if (!isEqual(propsDefault.defaultValue, customerValue)) {
        setCustomerValue(propsDefault.defaultValue);
      }
      // if (selectedCompanies.length > 0) {
      //   setCustomerValue(selectedCompanies[0].id);
      // } else {
      //   setCustomerValue(propsDefault.defaultValue);
      // }
    } else {
      if (!isEqual(selectedCustomerId, customerValue)) {
        setCustomerValue(selectedCustomerId);
      }
    }
  }, [companies, currentSubsidiary, customerValue, gettext, propsDefault.defaultValue, selectedCompanies, showAll]);

  //endregion useEffect
  const handleChange = event => {
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };
  //endregion Handlers

  return (
    <FabekeDropdown
      options={companies}
      componentLabel={componentLabel}
      defaultValue={customerValue}
      onChange={handleChange}
    />
  );

};

SubsidiaryDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};
