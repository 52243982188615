import {useUser} from "../../app/providers";
import {useEffect, useMemo, useState} from "react";
import {isEqual} from "lodash";

/**
 *
 * @param moduleNames array
 * @return {boolean}
 */
export const usePagePrivileges = moduleNames => {
  // if (typeof moduleNames === "string") {
  //   moduleNames = [moduleNames];
  // }
  const mergedCodes = useMemo(() => {
    const commonPermissions = ["developer"];
    return commonPermissions.concat(moduleNames);
  }, [moduleNames]);

  const [allow, setAllow] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [codes, setCodes] = useState(mergedCodes?.length ? mergedCodes : []);

  const {user} = useUser();

  useEffect(() => {
    if (mergedCodes?.length && !isEqual(mergedCodes, codes)) {
      setCodes(mergedCodes);
    }
  }, [codes, mergedCodes]);

  useEffect(() => {
    let p = [];
    p = permissions;
    if (user?.permissions?.length) {
      user?.permissions.forEach(role => {
        role?.modules.forEach(permission => {
          if (!p.includes(permission)) {
            p.push(permission);
          }
        });
      });
    }

    if (!isEqual(permissions, p)) {
      setPermissions(p);
    }
  }, [permissions, user?.permissions]);

  useEffect(() => {
    if (codes?.length) {
      codes.forEach((moduleName) => {
        if (moduleName && permissions && permissions.length > 0) {
          const module = permissions.find(item => item === moduleName);
          if (module) {
            setAllow(true);
          }
        } else if (moduleName === "allow-to-all") {
          setAllow(true);
        }
      });
    }
  }, [codes, permissions]);

  return allow;
};
