import React, {useContext} from 'react';
import {saveAs} from 'file-saver';
import {usePartnersList} from "../../../hooks/partners/usePartnersList";
import {Button} from "@mui/material";
import {useLang, useTextDomainContext} from "../../providers";
import {palette as AbsoluteSightPalatte} from "../../providers/MaterialThemeProvider/absolute-sight/palette";
import {palette as FabekePalatte} from "../../providers/MaterialThemeProvider/fabeke/palette";
import {palette as EnergiteamPalatte} from "../../providers/MaterialThemeProvider/energiteam/palette";
import {emailConfirmEmail, emailFooter, emailForgotPassword, emailHeader} from "./components";

const emailTemplates = [
  {
    code: "confirm-email",
    name: "Confirm Email"
  },
  {
    code: "forget-password",
    name: "Forgot Password"
  }
];

export const DeveloperGenerateEmail = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const locale = useLang();
  const {partnerList} = usePartnersList();
  const buttonList = [];
  const colors = {
    "absolute-sight": AbsoluteSightPalatte.primary.main,
    "fabeke": FabekePalatte.primary.main,
    "energiteam": EnergiteamPalatte.primary.main,
  };

  emailTemplates.forEach((template, index1) => {
    partnerList.forEach((partner, index2) => {
      buttonList.push(
        <Button
          key={index1 + "-" + index2}
          variant="contained"
          // color="primary"
          onClick={(e) => saveEmail(e, template, partner)}
          sx={{
            m: 1,
            backgroundColor: colors[partner.key]
          }}
        >
          {template.name}: {partner.name}
        </Button>
      );

    });
    buttonList.push(<br key={index1}/>);
  });


  const emailContent = (template, partner) => {
    let returnHtml = emailHeader(gettext, locale, partner, colors);
    switch (template.code) {
      case "confirm-email":
        returnHtml += emailConfirmEmail(gettext);
        break;
      case "forget-password":
        returnHtml += emailForgotPassword(gettext);
        break;
      default:
        returnHtml = "";
        break;
    }
    returnHtml += emailFooter(gettext);
    return returnHtml;
  };

  const saveEmail = (event, template, partner) => {
    const filename = `${template.code}-${partner.key}-${locale}`;
    const emailText = emailContent(template, partner);
    const blob = new Blob([emailText], {
      type: 'text/html;charset=utf-8'
    });
    saveAs(blob, `${filename}.html`);
  };

  return (
    <>
      {buttonList}
    </>
  );
};