import {TableLoadingStateEnergyStatement, TableView1} from "../../components";
import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "../../providers";
import * as PropsType from "prop-types";
import {getMonthsArray} from "../../../utils";
// import "../../types/ConsumptionEnergyData";

export const EnergyMonthlyStatement = (props) => {
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  // const locale = useLang();
  // const {user} = users();
  // const {currentTeam: customer} = user || {
  // };
  // let columnsData = [];
  // let rowsData = [];

  // const loading = false;
  // const filters = {
  //   meterType: props.meterType,
  //   customer: customer,
  //   year: props.year,
  // };
  //
  // const {
  //   data,
  //   loading
  // } = useConsumptionEnergyData(filters);

  // if (data) {
  //   columnsData = EnergyMonthlyColumns(data, gettext, locale);
  //   rowsData = EnergyMonthlyRows(data);
  // }

  // const monthList = getMonthsArray(gettext);
  // const monthList = useMemo(() => {
  //   return getMonthsArray(gettext);
  // }, [gettext]);

  const [fileNameForExport, setFileNameForExport] = useState("energy-monthly-statement");

  const propsDefault = {
    title: gettext("One Month Energy Consumption (kWh)")
  };
  const [title, setTitle] = useState(propsDefault.title);
  const [columns, setColumns] = useState(props.columns);
  const [rows, setRows] = useState(props.rows);
  const [rowsData, setRowsData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fullMonthName, setFullMonthName] = useState("");

  useEffect(() => {
    setFileNameForExport(props.fileNameForExport);
  }, [props.fileNameForExport]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    const monthList = getMonthsArray(gettext);
    if (props.month === "all") {
      setFullMonthName(gettext("Full Year"));
    } else {
      setFullMonthName(monthList.find(obj => obj.value === props.month).label);
    }
  }, [gettext, props.month]);

  useEffect(() => {
    if (props.isClimate) {
      setTitle(fullMonthName + " " + gettext("CO₂e (Kg)"));
    } else {
      setTitle(fullMonthName + " " + gettext("Energy Consumption (kWh)"));
    }
  }, [fullMonthName, gettext, props.isClimate]);

  // useEffect(() => {
  //   if (columnsData.length) {
  //     setColumns(columnsData);
  //   }
  // }, [columnsData]);
  //
  // useEffect(() => {
  //   if (rowsData.length) {
  //     setRows(rowsData);
  //   }
  // }, [rowsData]);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  useEffect(() => {
    setRowsData(props.rows);
  }, [props.rows]);

  // useEffect(() => {
  //   if (data) {
  //     const columnsData = EnergyMonthlyColumns(data, gettext, locale);
  //     const allRowsData = EnergyMonthlyRows(data, gettext);
  //
  //     if (columnsData?.length) {
  //       setColumns(columnsData);
  //     } else {
  //       setColumns([]);
  //     }
  //
  //     if (allRowsData["all"]?.length) {
  //       setRowsData(allRowsData);
  //     }
  //   }
  // }, [data, gettext, locale]);


  useEffect(() => {
    if (!props.month) {
      if (rowsData["all"]?.length) {
        setRows(rowsData["all"]);
      } else {
        setRows([]);
      }
    } else {
      if (rowsData[props.month]?.length) {
        setRows(rowsData[props.month]);
      } else {
        setRows([]);
      }
    }
  }, [props.month, rowsData]);

  // const columns = [
  //   // {
  //   //   field: "ts",
  //   //   headerName: gettext("Date"),
  //   //   flex: 1,
  //   //   type: "date",
  //   //   valueGetter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = new Date(value);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   },
  //   //   valueFormatter: (value) => {
  //   //     let returnValue = "";
  //   //     if (value) {
  //   //       if (value === "Summary") {
  //   //         returnValue = value;
  //   //       } else {
  //   //         returnValue = moment(value).format(segmentList[segment].dateFormat);
  //   //       }
  //   //     }
  //   //     return returnValue;
  //   //   }
  //   // },
  //   {
  //     field: "installation",
  //     headerName: gettext("Installation"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "measure",
  //     headerName: gettext("measure"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "company",
  //     headerName: gettext("company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "network_company",
  //     headerName: gettext("network_company"),
  //     // flex: 1,
  //     width: 200,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "Installation_address",
  //     headerName: gettext("Installation_address"),
  //     // flex: 1,
  //     width: 300,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "house",
  //     headerName: gettext("house"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "post_code",
  //     headerName: gettext("post_code"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "by",
  //     headerName: gettext("by"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "comment",
  //     headerName: gettext("comment"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "net_tarif",
  //     headerName: gettext("net_tarif"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "cvr",
  //     headerName: gettext("cvr"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "annual_consumption",
  //     headerName: gettext("annual_consumption"),
  //     // flex: 1,
  //     width: 100,
  //     // type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sum",
  //     headerName: gettext("sum"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "percent",
  //     headerName: gettext("percent"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jan",
  //     headerName: gettext("Jan 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "feb",
  //     headerName: gettext("Feb 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "mar",
  //     headerName: gettext("Mar 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "apr",
  //     headerName: gettext("Apr 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "may",
  //     headerName: gettext("May 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jun",
  //     headerName: gettext("Jun 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "jul",
  //     headerName: gettext("Jul 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "aug",
  //     headerName: gettext("Aug 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "sep",
  //     headerName: gettext("Sep 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "oct",
  //     headerName: gettext("Oct 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "nov",
  //     headerName: gettext("Nov 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   {
  //     field: "dec",
  //     headerName: gettext("Dec 2024"),
  //     // flex: 1,
  //     width: 100,
  //     type: "number",
  //     // valueFormatter: (value) => value && value.toFixed(2)
  //   },
  //   // {
  //   //   field: "consumptionConversionRate",
  //   //   headerName: gettext("Conversion Rate (g CO₂/kWh)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(2)
  //   // },
  //   // {
  //   //   field: "consumptionCo2",
  //   //   headerName: gettext("CO₂ (kilogram)"),
  //   //   flex: 1,
  //   //   type: "number",
  //   //   valueFormatter: (value) => value && value.toFixed(0)
  //   // }
  //   // {
  //   //   field: "consumptionCost",
  //   //   headerName: gettext("Cost (DKK)"),
  //   //   flex: 1,
  //   //   type: "number"
  //   // }
  // ];
  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
    // columns: {
    //   columnVisibilityModel: {
    //     id: false,
    //   }
    // }
  };

  const handleChangeEnergyStatement = (obj) => {
    if (typeof props.onChangeEnergyStatement === "function") {
      props.onChangeEnergyStatement(obj);
    }
  };

  return (
    <>
      {columns.length > 0 && (
        <TableView1
          title={title}
          // rows={metersData}
          rows={rows}
          // rows={[]}
          columns={columns}
          loading={loading}
          initialState={initialState}
          showGridToolbar
          // showEnergyStatementType
          showGridToolbarEnergyStatementExport
          showGridToolbarQuickFilter
          fileNameForExport={fileNameForExport}
          excelData={props.excelData}
          onChangeEnergyStatement={handleChangeEnergyStatement}
          // selectedEnergyStatement="monthly-statement"
        />
      )}
      {loading && (
        TableLoadingStateEnergyStatement()
        // gettext("Loading")
      )}
      {!loading && !data?.consumptionEnergyData?.consumptionData?.length && (
        TableLoadingStateEnergyStatement()
        // gettext("Still Loading")
      )}

      {!loading && data?.consumptionEnergyData?.consumptionData.length > 0 && !rows.length && (
        TableLoadingStateEnergyStatement()
      )}

      {!loading && !(Array.isArray(data?.consumptionEnergyData?.consumptionData) && data?.consumptionEnergyData?.consumptionData.length > 0 && data?.consumptionEnergyData?.consumptionData[0]?.details?.data) && (
        <>
          {gettext("No data found")}<br/>
          {gettext("If the data is heavy, it may still be loading.")}<br/>
        </>
      )}

      {/*{!loading && columns.length === 0 && (*/}
      {/*  <>*/}
      {/*    {gettext("No data found")}<br/>*/}
      {/*    {gettext("If the data is heavy, it may still be loading.")}<br/>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

EnergyMonthlyStatement.propTypes = {
  onChangeEnergyStatement: PropsType.func,
  fileNameForExport: PropsType.string,
  meterType: PropsType.string.isRequired,
  year: PropsType.number.isRequired,
  month: PropsType.string.isRequired,
  columns: PropsType.array.isRequired,
  rows: PropsType.array.isRequired,
  excelData: PropsType.array,
};