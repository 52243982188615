import React from "react";
import PropTypes from "prop-types";
import {CustomerDropdownCommon} from "./";

export const CustomerDropdown = (props) => {
  return (
    <CustomerDropdownCommon
      componentLabel={props.componentLabel}
      defaultValue={props.defaultValue}
      showAll={props.showAll}
      onChange={props.onChange}
      visualStyle="old"
    />
  );
};

CustomerDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func
};
