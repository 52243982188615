import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {FilterBox} from "../Boxes";
import {BuildingDropdown,
  ConsumptionOrCo2Dropdown,
  EnergyReportTypeDropdown,
  EnergyTypeDropdown,
  MeterDropdown,
  MonthsDropdown,
  SegmentDropDown,
  StatusDropdown} from "../Dropdown";
import {MultiDatePickerDmy, YearPicker} from "../DatePicker";
import PropTypes from "prop-types";
import {segmentList} from "../../../utils";

export const FilterBar1 = (props) => {
  const segments = segmentList();

  //region React Hooks (useState)
  const propsDefault = {
    currentTeam: undefined,
    building: undefined,
    meter: undefined,
    energyType: undefined,
    shortSegment: "m",
    reportType: "",
    selectedDate: {
      startDate: new Date(),
      endDate: new Date()
    },
    selectedYearDate: new Date(),
    selectedMonth: 'jan',
    segmentName: "year",
    consumptionOrCo2: "consumption",
    showBuilding: false,
    showMeter: false,
    showEnergyType: false,
    showDate: false,
    showYear: false,
    showMonth: false,
    showBuildingAll: false,
    showMeterAll: false,
    showEnergyTypeAll: false,
    showShortSegment: false,
    showReportType: false,
    showConsumptionOrCo2: false,
    status: false
  };

  const [currentTeam, setCurrentTeam] = useState(typeof props.currentTeam === "undefined" ? propsDefault.currentTeam : props.currentTeam);
  const [building, setBuilding] = useState(typeof props.building === "undefined" ? propsDefault.building : props.building);
  const [meter, setMeter] = useState(props.meter || propsDefault.meter);
  const [energyType, setEnergyType] = useState(typeof props.energyType === "undefined" ? propsDefault.energyType : props.energyType);
  const [consumptionOrCo2, setConsumptionOrCo2] = useState(typeof props.consumptionOrCo2 === "undefined" ? propsDefault.consumptionOrCo2 : props.consumptionOrCo2);
  const [startDate, setStartDate] = useState(typeof props.startDate === "undefined" ? propsDefault.selectedDate.startDate : new Date(props.startDate));
  const [endDate, setEndDate] = useState(typeof props.endDate === "undefined" ? propsDefault.selectedDate.endDate : props.endDate);
  const [selectedYearDate, setSelectedYearDate] = useState(typeof props.selectedYearDate === "undefined" ? propsDefault.selectedYearDate : props.selectedYearDate);
  const [selectedMonth, setSelectedMonth] = useState(typeof props.selectedMonth === "undefined" ? propsDefault.selectedMonth : props.selectedMonth);
  const [segment, setSegment] = useState(typeof props.segment === "undefined" ? propsDefault.segmentName : props.segment);
  const [showBuilding, setShowBuilding] = useState(typeof props.showBuilding === "undefined" ? propsDefault.showBuilding : props.showBuilding);
  const [showMeter, setShowMeter] = useState(typeof props.showMeter === "undefined" ? propsDefault.showMeter : props.showMeter);
  const [showEnergyType, setShowEnergyType] = useState(typeof props.showEnergyType === "undefined" ? propsDefault.showEnergyType : props.showEnergyType);
  const [showDate, setShowDate] = useState(typeof props.showDate === "undefined" ? propsDefault.showDate : props.showDate);
  const [showYear, setShowYear] = useState(typeof props.showYear === "undefined" ? propsDefault.showYear : props.showYear);
  const [showMonth, setShowMonth] = useState(typeof props.showMonth === "undefined" ? propsDefault.showMonth : props.showMonth);
  const [showBuildingAll, setShowBuildingAll] = useState(typeof props.showBuildingAll === "undefined" ? propsDefault.showBuildingAll : props.showBuildingAll);
  const [showMeterAll, setShowMeterAll] = useState(typeof props.showMeterAll === "undefined" ? propsDefault.showMeterAll : props.showMeterAll);
  const [showEnergyTypeAll, setShowEnergyTypeAll] = useState(typeof props.showEnergyTypeAll === "undefined" ? propsDefault.showEnergyTypeAll : props.showEnergyTypeAll);
  const [dateRange, setDateRange] = useState({
    startDate: startDate,
    endDate: endDate
  });
  const [status, setStatus] = useState(typeof props.status === "undefined" ? propsDefault.status : props.status);
  const [shortSegment, setShortSegment] = useState(typeof props.shortSegment === "undefined" ? propsDefault.shortSegment : props.shortSegment);
  const [reportType, setReportType] = useState(typeof props.reportType === "undefined" ? propsDefault.reportType : props.reportType);
  const [showStatus, setShowStatus] = useState(typeof props.showStatus === "undefined" ? propsDefault.showStatus : props.showStatus);
  const [showShortSegment, setShowShortSegment] = useState(typeof props.showShortSegment === "undefined" ? propsDefault.showShortSegment : props.showShortSegment);
  const [showReportType, setShowReportType] = useState(typeof props.showReportType === "undefined" ? propsDefault.showReportType : props.showReportType);
  const [showConsumptionOrCo2, setShowConsumptionOrCo2] = useState(typeof props.showConsumptionOrCo2 === "undefined" ? propsDefault.showConsumptionOrCo2 : props.showConsumptionOrCo2);
  //endregion React Hooks (useState)

  //region React Hooks (useEffect)

  useEffect(() => {
    if (typeof props.getSelectedValues === "function") {
      props.getSelectedValues({
        building,
        meter,
        energyType,
        consumptionOrCo2,
        date: dateRange,
        segment,
        shortSegment,
        reportType,
        selectedYearDate,
        selectedMonth,
      });
    }

  }, [building, dateRange, energyType, meter, segment, shortSegment, reportType, selectedYearDate, selectedMonth, props, consumptionOrCo2]);

  useEffect(() => {
    if (typeof props.showBuilding !== "undefined") {
      setShowBuilding(props.showBuilding);
    }
  }, [props.showBuilding]);

  useEffect(() => {
    if (typeof props.showMeter !== "undefined") {
      setShowMeter(props.showMeter);
    }
  }, [props.showMeter]);

  useEffect(() => {
    if (typeof props.showEnergyType !== "undefined") {
      setShowEnergyType(props.showEnergyType);
    }
  }, [props.showEnergyType]);

  useEffect(() => {
    if (typeof props.showStatus !== "undefined") {
      setShowStatus(props.showStatus);
    }
  }, [props.showStatus]);

  useEffect(() => {
    if (typeof props.showShortSegment !== "undefined") {
      setShowShortSegment(props.showShortSegment);
    }
  }, [props.showShortSegment]);

  useEffect(() => {
    if (typeof props.showReportType !== "undefined") {
      setShowReportType(props.showReportType);
    }
  }, [props.showReportType]);

  useEffect(() => {
    if (typeof props.showDate !== "undefined") {
      setShowDate(props.showDate);
    }
  }, [props.showDate]);

  useEffect(() => {
    if (typeof props.showYear !== "undefined") {
      setShowYear(props.showYear);
    }
  }, [props.showYear]);

  useEffect(() => {
    if (typeof props.showMonth !== "undefined") {
      setShowMonth(props.showMonth);
    }
  }, [props.showMonth]);

  useEffect(() => {
    if (typeof props.showBuildingAll !== "undefined") {
      setShowBuildingAll(props.showBuildingAll);
    }
  }, [props.showBuildingAll]);

  useEffect(() => {
    if (typeof props.showMeterAll !== "undefined") {
      setShowMeterAll(props.showMeterAll);
    }
  }, [props.showMeterAll]);

  useEffect(() => {
    if (typeof props.showEnergyTypeAll !== "undefined") {
      setShowEnergyTypeAll(props.showEnergyTypeAll);
    }
  }, [props.showEnergyTypeAll]);

  useEffect(() => {
    if (typeof props.currentTeam !== "undefined") {
      setCurrentTeam(props.currentTeam);
    }
  }, [props.currentTeam]);

  useEffect(() => {
    if (typeof props.building !== "undefined") {
      setBuilding(props.building);
    }
  }, [props.building]);

  useEffect(() => {
    if (typeof props.meter !== "undefined") {
      setMeter(props.meter);
    }
  }, [props.meter]);

  useEffect(() => {
    if (typeof props.energyType !== "undefined") {
      setSegment(props.energyType);
    }
  }, [props.energyType]);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange({
        startDate: new Date(startDate),
        endDate: new Date(endDate)
      });
    }
  }, [endDate, startDate]);

  useEffect(() => {
    if (props.startDate) {
      setStartDate(new Date(props.startDate));
    }
  }, [props.startDate]);

  useEffect(() => {
    if (props.endDate) {
      setEndDate(new Date(props.endDate));
    }
  }, [props.endDate]);

  useEffect(() => {
    if (typeof props.segment !== "undefined") {
      setSegment(props.segment);
    }
  }, [props.segment]);

  useEffect(() => {
    if (typeof props.shortSegment !== "undefined") {
      setShortSegment(props.shortSegment);
    }
  }, [props.shortSegment]);

  useEffect(() => {
    if (typeof props.reportType !== "undefined") {
      setReportType(props.reportType);
    }
  }, [props.reportType]);

  useEffect(() => {
    if (typeof props.showConsumptionOrCo2 !== "undefined") {
      setShowConsumptionOrCo2(props.showConsumptionOrCo2);
    }
  }, [props.showConsumptionOrCo2]);
  //endregion React Hooks (useEffect)

  //region Handlers
  const hitGetSelectedValues = () => {
    // if (typeof props.getSelectedValues === "function") {
    //   props.getSelectedValues({
    //     building,
    //     meter,
    //     energyType,
    //     consumptionOrCo2,
    //     date: dateRange,
    //     segment,
    //     shortSegment,
    //     reportType,
    //     selectedYearDate,
    //     selectedMonth,
    //   });
    // }
  };

  const handleBuildingChange = event => {
    setBuilding(event);
    hitGetSelectedValues();
  };

  const handleMeterChange = event => {
    setMeter(event);
    hitGetSelectedValues();
  };

  const handleEnergyTypeChange = event => {
    setEnergyType(event);
    hitGetSelectedValues();
  };

  const handleStatusChange = event => {
    setStatus(event);
    hitGetSelectedValues();
  };

  const handleShortSegmentChange = event => {
    setShortSegment(event);
    hitGetSelectedValues();
  };

  const handleEnergyReportTypeChange = event => {
    setEnergyType(event);
    hitGetSelectedValues();
  };

  const handleConsumptionOrCo2DChange = event => {
    setConsumptionOrCo2(event);
    hitGetSelectedValues();
  };

  const handleMonthChange = event => {
    setSelectedMonth(event);
    hitGetSelectedValues();
  };

  const handleGetSelectedDate = (dateArray, selectedSegment) => {
    const {
      startDate,
      endDate
    } = dateArray;

    if (selectedSegment !== segment) {
      setShortSegment(segments[selectedSegment].shortSegment);
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setSegment(selectedSegment);
    hitGetSelectedValues();
  };

  const handleGetSelectedYearDate = (date) => {
    setSelectedYearDate(date);
    hitGetSelectedValues();
  };
  //endregion Handlers

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FilterBox>
          <Grid container spacing={1} alignItems="center">
            {showEnergyType && (
              <Grid item xs={12} sm={"auto"}>
                <EnergyTypeDropdown
                  onChange={handleEnergyTypeChange}
                  defaultValue={energyType ? energyType.value : energyType}
                  showAll={showEnergyTypeAll}
                />
              </Grid>
            )}
            {showConsumptionOrCo2 && (
              <Grid item xs={12} sm={"auto"}>
                <ConsumptionOrCo2Dropdown
                  onChange={handleConsumptionOrCo2DChange}
                  defaultValue={consumptionOrCo2 ? consumptionOrCo2.value : consumptionOrCo2}
                />
              </Grid>
            )}
            {showBuilding && (
              <Grid item xs={12} sm={"auto"}>
                <BuildingDropdown
                  onChange={handleBuildingChange}
                  defaultValue={building ? building.value : building}
                  currentTeam={currentTeam}
                  showAll={showBuildingAll}
                />
              </Grid>
            )}
            {showMeter && (
              <Grid item xs={12} sm={"auto"}>
                <MeterDropdown
                  onChange={handleMeterChange}
                  energyType={energyType?.value ? energyType.value : energyType}
                  defaultValue={meter?.value ? meter.value : meter}
                  building={building?.value ? building.value : building}
                  currentTeam={currentTeam}
                  showAll={showMeterAll}
                />
              </Grid>
            )}
            {showStatus && (
              <Grid item xs={12} sm={"auto"}>
                <StatusDropdown
                  onChange={handleStatusChange}
                  defaultValue={status?.value ? status.value : status}
                  showAll={showStatus}
                />
              </Grid>
            )}
            <Grid item xs={1} sm textAlign="end">
            </Grid>
            {showDate && (
              <Grid item xs={4} sm textAlign="end">
                <MultiDatePickerDmy
                  getSelectedDate={handleGetSelectedDate}
                  selectedDate={dateRange}
                  segment={segment}
                  futureDates={true}
                />
              </Grid>
            )}
            {showMonth && (
              <Grid item xs={4} sm={"auto"} textAlign="end">
                <MonthsDropdown
                  onChange={handleMonthChange}
                />
              </Grid>
            )}
            {showYear && (
              <Grid item xs={12} sm={"auto"} textAlign="end">

                <YearPicker
                  getSelectedYearDate={handleGetSelectedYearDate}
                  selectedYearDate={selectedYearDate}
                  futureDates={true}
                />
              </Grid>
            )}
            {showReportType && (
              <Grid item xs={12} sm={"auto"} textAlign="end">
                <EnergyReportTypeDropdown
                  onChange={handleEnergyReportTypeChange}
                  defaultValue={reportType?.value ? reportType.value : reportType}
                />
              </Grid>
            )}
            {showShortSegment && (
              <Grid item xs={12} sm={"auto"} textAlign="end">
                <SegmentDropDown
                  onChange={handleShortSegmentChange}
                  defaultValue={shortSegment?.value ? shortSegment.value : shortSegment}
                />
              </Grid>
            )}
          </Grid>
        </FilterBox>
      </Grid>
    </Grid>
  );
};

FilterBar1.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  currentTeam: PropTypes.string,
  building: PropTypes.string,
  meter: PropTypes.string,
  energyType: PropTypes.string,
  segment: PropTypes.string,
  shortSegment: PropTypes.string,
  reportType: PropTypes.string,
  showBuilding: PropTypes.bool,
  showMeter: PropTypes.bool,
  showEnergyType: PropTypes.bool,
  status: PropTypes.bool,
  showDate: PropTypes.bool,
  showYear: PropTypes.bool,
  showMonth: PropTypes.bool,
  showBuildingAll: PropTypes.bool,
  showMeterAll: PropTypes.bool,
  showEnergyTypeAll: PropTypes.bool,
  showShortSegment: PropTypes.bool,
  showReportType: PropTypes.bool,
  showConsumptionOrCo2: PropTypes.bool,
  getSelectedValues: PropTypes.func.isRequired
};