// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import React from "react";
import ReactGA from "react-ga4";
import {createRoot} from "react-dom/client";
import App from "./app/App";
import "./assets/scss/index.scss";

const {REACT_APP_GOOGLE_ANALYTICS_UA} = process.env;

ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_UA);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);