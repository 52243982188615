import React, {useContext, useEffect, useState} from "react";
import {useLang, useTextDomainContext, useUser} from "app/providers";
import {FaUserEdit} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {useDealsList, useDeleteDeal} from "../../../hooks";
import {Button, IconButton, Tooltip} from "@mui/material";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {ConfirmNotification, FilterBar1, TableView1} from "../../components";
import moment from "moment/moment";
import {formatDateShort, toLocaleNumber, urlFriendlyString} from "../../../utils";

/**
 *
 * @param props
 */
export const DealsList = (props) => {
  const {restricted} = props;
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const locale = useLang();
  const navigate = useNavigate();
  const {user} = useUser();
  let {currentSubsidiary: customer} = user || {
  };
  if (!customer) {
    customer = "all";
  }

  //region React Hooks (useState)
  const [building, setBuilding] = useState("all");
  const [meter, setMeter] = useState("all");
  const [energyType, setEnergyType] = useState("all");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [dialogParameters, setDialogParameters] = useState({
  });
  const [fileNameForExport, setFileNameForExport] = useState("energy-contracts-export");
  //endregion  React Hooks (useState)

  const filters = {
    customer,
    building,
    meter,
    energyType,
    fromDate: moment(fromDate).format("YYYY-MM-DDTHH:mm:ss"),
    toDate: moment(toDate).format("YYYY-MM-DDTHH:mm:ss")
  };
  const {
    dealsList,
    loading
  } = useDealsList(filters);
  const {
    delete: deleteDeal,
    deleting
  } = useDeleteDeal();

  useEffect(() => {
    setFileNameForExport(urlFriendlyString("energy-contracts-" + building + "-" + meter + "-" + energyType + "-" + fromDate + "-" + toDate));
  }, [building, energyType, fromDate, meter, toDate]);

  // const [disabledDeleteButton, setDisabledDeleteButton] = useState(true);

  // const { dealsList } = useDealsList();
  // const [editableRow, seteditableRow] = useState({});
  // const [edit_show, setedit] = useState(false);
  // const [delete_show, setdelete] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);

  // const handleClose_edit = () => setedit(false);

  // const handleShow_edit = () => setedit(true);

  // const handleClose_delete = () => setdelete(false);

  // const handleShow_delete = () => setdelete(true);

  /**
   *
   * @param row
   */
  function edit_functionality(row) {
    // seteditableRow(row);
    navigate(`/data/deals/edit/${row.id}`);
  }

  /**
   *
   * @param row
   */
  function delete_functionality(row) {
    setDialogParameters({
      action: "delete-single",
      ids: [row.id]
    });
    setShowConfirm(true);
  }

  // const handleOnRowSelectionModelChange = (ids) => {
  // setSelectedIds(ids);
  // if (ids.length) {
  //   setDisabledDeleteButton(false);
  // } else {
  //   setDisabledDeleteButton(true);
  // }
  // };

  /**
   *
   * @param row
   * @param action
   */
  function performAction(row, action) {
    switch (action) {
      case "edit":
        edit_functionality(row);
        break;
      case "delete":
        delete_functionality(row);
        break;
      default:
        break;
    }
  }

  /**
   *
   * @param params
   */
  function columnFormatter(params) {
    return (
      <div className="w-100 d-flex justify-content-center">
        {!restricted && (
          <Tooltip title={gettext("Edit")} arrow>
            <IconButton onClick={() => performAction(params.row, "edit")}>
              <FaUserEdit size={16}/>
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={gettext("Delete")} arrow>
          <IconButton onClick={() => performAction(params.row, "delete")}>
            <MdDelete size={16}/>
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  // console.log(moment.localeData("2024-01-01T00:00:00").longDateFormat('L'));

  const columns = [
    {
      field: "id",
      headerName: gettext("ID"),
      width: 225,
      checkboxSelection: true
    },
    {
      field: "customer",
      headerName: gettext("Customer"),
      // flex: 1,
      width: 300,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "building",
      headerName: gettext("Building"),
      flex: 1,
      minWidth: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "meter",
      headerName: gettext("Installation number"),
      flex: 1,
      minWidth: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "energyType",
      headerName: gettext("Energy Type"),
      // flex: 1,
      width: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "mainPriceType",
      headerName: gettext("Main Price Type"),
      // flex: 1,
      width: 150,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "fromDate",
      headerName: gettext("From"),
      headerAlign: "left",
      width: 100,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => formatDateShort(value),
    },
    {
      field: "toDate",
      headerName: gettext("To"),
      headerAlign: "left",
      width: 100,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => formatDateShort(value),
    },
    {
      field: "currency",
      headerName: gettext("Currency"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "priceType",
      headerName: gettext("Price Type"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "pricePrice",
      headerName: gettext("Price Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "priceKwh",
      headerName: gettext("Price kWh"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "priceShare",
      headerName: gettext("Price Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "systemType",
      headerName: gettext("System Type"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "systemPrice",
      headerName: gettext("System Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "systemKwh",
      headerName: gettext("System kWh"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "systemShare",
      headerName: gettext("System Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "epadType",
      headerName: gettext("EPAD Type"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "epadPrice",
      headerName: gettext("EPAD Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "epadKwh",
      headerName: gettext("EPAD kWh"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "epadShare",
      headerName: gettext("EPAD Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "profileType",
      headerName: gettext("Profile Type"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "string"
    },
    {
      field: "profilePrice",
      headerName: gettext("Profile Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "profileKwh",
      headerName: gettext("Profile kWh"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "profileShare",
      headerName: gettext("Profile Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "markupPrice",
      headerName: gettext("Markup Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "markupShare",
      headerName: gettext("Markup Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "commissionPrice",
      headerName: gettext("Spot Premium Price"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "commissionShare",
      headerName: gettext("Spot Premium Share"),
      // flex: 1,
      width: 100,
      headerAlign: "left",
      type: "number",
      valueFormatter: (value) => toLocaleNumber(locale, value, 2)
    },
    {
      field: "createdAt",
      headerName: gettext("Created At"),
      headerAlign: "left",
      width: 150,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => formatDateShort(value),
    },
    {
      field: "updatedAt",
      headerName: gettext("Updated At"),
      headerAlign: "left",
      width: 150,
      type: "date",
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) => formatDateShort(value),
    },
    {
      field: "actions",
      headerName: gettext("Actions"),
      hideable: false,
      maxWidth: "100",
      // flex: 1,
      // minWidth: 80,
      // maxWidth: 80,
      // headerAlign: "center",
      // type: "actions",
      renderCell: params => columnFormatter(params)
    }
  ];

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 10
      }
    },
    columns: {
      columnVisibilityModel: {
        id: false,
        customer: false,
        currency: false,
        // mainPriceType: false,
        priceType: false,
        pricePrice: false,
        priceKwh: false,
        priceShare: false,
        systemType: false,
        systemPrice: false,
        systemKwh: false,
        systemShare: false,
        epadType: false,
        epadPrice: false,
        epadKwh: false,
        epadShare: false,
        profileType: false,
        profileKwh: false,
        profilePrice: false,
        profileShare: false,
        markupPrice: false,
        markupShare: false,
        commissionPrice: false,
        commissionShare: false,
        createdAt: false,
        updatedAt: false
      }
    }
  };

  const handleGetSelectedValues = (selectedValues) => {
    if (selectedValues?.building?.value) {
      setBuilding(selectedValues.building.value);
    }

    if (selectedValues?.meter?.value) {
      setMeter(selectedValues.meter.value);
    }

    if (selectedValues?.energyType?.value) {
      setEnergyType(selectedValues.energyType.value);
    }

    if (selectedValues?.date?.startDate) {
      setFromDate(selectedValues.date.startDate.toISOString());
    }

    if (selectedValues?.date?.endDate) {
      setToDate(selectedValues.date.endDate.toISOString());
    }

  };

  const handleOnClickDeleteMultiple = (ids) => {
    setDialogParameters({
      action: "delete-multi",
      ids: ids
    });
    setShowConfirm(true);
  };

  const handleGetResult = (obj) => {
    if (obj.result === "yes") {
      deleteDeal({
        ids: obj?.parameters?.ids
      });
    }
    setShowConfirm(false);
  };

  const preToolbar = () => {
    return (
      <>
        {/* <Button variant="contained" component={Link} to={"/data/deals/add"}> */}
        {/*   {gettext("Add contract")} */}
        {/* </Button> */}
        {/* <Button disabled={disabledDeleteButton} variant="contained" color={"error"}> */}
        {/*   Delete */}
        {/* </Button> */}
      </>
    );
  };

  const postToolbar = () => {
    return (
      <>
        <Button variant="contained" component={Link} to={"/data/deals/add"} sx={{
          ml: 1
        }}>
          {gettext("Add contract")}
        </Button>
        {/* <Button disabled={disabledDeleteButton} variant="contained" color={"error"}> */}
        {/*   Delete */}
        {/* </Button> */}
      </>
    );
  };

  const headerRight = () => {
    return (
      <>
        {/* <Button variant="contained" component={Link} to={"/data/deals/add"}> */}
        {/*   {gettext("Add contract")} */}
        {/* </Button> */}
      </>
    );
  };

  return (
    <>
      <TableView1
        title={gettext("Energy Contracts")}
        rows={dealsList}
        // rows={[]}
        columns={columns}
        loading={loading}
        checkboxSelection
        initialState={initialState}
        preToolbar={preToolbar()}
        postToolbar={postToolbar()}
        headerRight={headerRight()}
        showGridToolbar
        showGridToolbarExport
        showGridToolbarDeleteButton
        fileNameForExport={fileNameForExport}
        beforeTable={
          <>
            <FilterBar1
              getSelectedValues={handleGetSelectedValues}
              showBuilding={true}
              showMeter={true}
              showEnergyType={true}
              showDate={true}
              showBuildingAll={true}
              showMeterAll={true}
              showEnergyTypeAll={false}
            />
            <br/>
          </>
        }
        // onRowSelectionModelChange={handleOnRowSelectionModelChange}
        onClickDeleteMultiple={handleOnClickDeleteMultiple}
      />

      {/* <DataGridComponent */}
      {/*   rows={dealsList} */}
      {/*   columns={columns} */}
      {/*   loading={loading} */}
      {/*   disableSelectionOnClick={true} */}
      {/*   initialState={{ pinnedColumns: { right: ["actions"] } }} */}
      {/*   autoHeight */}
      {/*   // getRowId={row => row.Id} */}
      {/* /> */}

      {/* <AddDealForm */}
      {/*   open={edit_show} */}
      {/*   handleClose={handleClose_edit} */}
      {/*   editableRow={editableRow} */}
      {/* /> */}

      {/* <DeleteModel */}
      {/*   show={delete_show} */}
      {/*   onHide={handleClose_delete} */}
      {/*   editableRow={editableRow} */}
      {/*   // getTeamMembers={props.getTeamMembers} */}
      {/* /> */}
      <ConfirmNotification
        isOpene={showConfirm}
        parameters={dialogParameters}
        getResult={handleGetResult}
      />
      {deleting ? (
        <div
          className="position-absolute bg-dark opacity-50 w-100 h-100 d-flex justify-content-center align-items-center">
          {/*<Spinner animation="border" variant="light"/>*/}
        </div>
      ) : null}
    </>
  );
};
