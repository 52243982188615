import {useQuery} from "@apollo/client";
import {useUser} from "../../app/providers";
import {ME} from "./commands";
import {setCacheWithExpiry} from "../../utils";

// import { useContext } from "react";
// import { useSnackbar } from "notistack";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useMe(onCompleted = () => null, onError = () => null) {
  // const { enqueueSnackbar } = useSnackbar();
  // const { TextDomainContext } = useTextDomainContext();
  // const { gettext } = useContext(TextDomainContext);

  const {setUser} = useUser();

  // const {unsetToken} = useAuth();

  const {
    data,
    loading,
    refetch
  } = useQuery(ME, {
    onCompleted: ({me}) => {
      try {
        setUser({
          ...me
        });

        const key = "me";
        const cacheTTL = 3600; // 3600 = 1 hour
        setCacheWithExpiry(key, me, cacheTTL);

        onCompleted(me);
      } catch (error) {
        // sendSlackDebug("comeMe onComplete catch");
        // unsetToken();
      }
    },
    onError: (error) => {
      // sendSlackDebug("comeMe onError");
      // unsetToken();
      onError();

      // enqueueSnackbar(
      //   gettext(
      //     "Unable To Validate The Current User. Logging Out!. Please Login again."
      //   ),
      //   {
      //     variant: "error"
      //   }
      // );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    refetchMe: refetch,
    user: data?.me,
    loading,
  };
}
