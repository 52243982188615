import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext, useUser} from "../../providers";
import {useCompanyBuildings} from "../../../hooks";
import PropTypes from "prop-types";
import {FabekeDropdown} from "./FabekeDropdown";
import {SimpleDropdown} from "./SimpleDropdown";

export const BuildingDropdownCommon = (props) => {
  //region Data
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {user} = useUser();
  const {
    currentTeam: currentTeamDefault,
    currentSubsidiary: currentSubsidiaryDefault,
  } = user || {
  };
  //endregion Data

  //region UseState [React Hooks]
  const propsDefault = {
    defaultValue: "all",
    showAll: false,
    componentLabel: gettext("Location"),
    currentTeam: currentTeamDefault,
    currentSubsidiary: currentSubsidiaryDefault,
    buildings: [{
      label: gettext("All"),
      value: "all"
    }]
  };

  const [buildingValue, setBuildingValue] = useState(props.defaultValue ? props.defaultValue : propsDefault.defaultValue);
  const [showAll, setShowAll] = useState(props.showAll ? props.showAll : propsDefault.showAll);
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);
  const [currentTeam, setCurrentTeam] = useState(props.currentTeam ? props.currentTeam : propsDefault.currentTeam);
  const [currentSubsidiary, setCurrentSubsidiary] = useState(props.currentSubsidiary ? props.currentSubsidiary : propsDefault.currentSubsidiary);
  const [buildings, setBuildings] = useState(propsDefault.buildings);
  //endregion UseState

  const {buildings: buildingData} = useCompanyBuildings(currentTeam, currentSubsidiary);

  //region React Hooks (useEffect)
  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      setBuildingValue(propsDefault.defaultValue);
    } else {
      setBuildingValue(props.defaultValue);
    }
  }, [props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.showAll === "undefined") {
      setShowAll(propsDefault.showAll);
    } else {
      setShowAll(props.showAll);
    }
  }, [props.showAll, propsDefault.showAll]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    if (typeof props.currentTeam === "undefined") {
      setCurrentTeam(propsDefault.currentTeam);
    } else {
      setCurrentTeam(props.currentTeam);
    }
  }, [props.currentTeam, propsDefault.currentTeam]);

  useEffect(() => {
    if (typeof props.currentSubsidiary === "undefined") {
      setCurrentSubsidiary(propsDefault.currentSubsidiary);
    } else {
      setCurrentSubsidiary(props.currentSubsidiary);
    }
  }, [props.currentSubsidiary, propsDefault.currentSubsidiary]);

  useEffect(() => {
    if (buildingData && buildingData.length > 0) {
      const buildingsArray = [];
      if (showAll) {
        buildingsArray.push({
          label: gettext("All"),
          value: "all"
        });
      }

      const firstBuildingValue = buildingData.find(o => o.value === buildingValue);
      if (!firstBuildingValue) {
        // Building data changed
        if (showAll && buildingValue === propsDefault.defaultValue) {
          //
        } else if (showAll) {
          setBuildingValue(propsDefault.defaultValue);
        } else if (buildingData[0].value) {
          setBuildingValue(buildingData[0].value);
        }
      }
      buildingData.map(building => {
        buildingsArray.push(building);
        return true;
      });
      setBuildings(buildingsArray);
    } else {
      if (showAll) {
        // The following lines cause infinite loop in use Effect when there is no data

        // setBuildings([{
        //   label: gettext("All"),
        //   value: "all"
        // }]);
        setBuildingValue(propsDefault.defaultValue);
      }
    }
  }, [buildingData, buildingValue, gettext, propsDefault.defaultValue, showAll]);
  //endregion React Hooks (useEffect)

  //region Handlers
  const handleChange = event => {
    props.onChange(event);
    // if (typeof props.onChange === "function") {
    //   if (props.visualStyle === "simple") {
    //     props.onChange(event.target);
    //   } else if (props.visualStyle === "old") {
    //     props.onChange(event);
    //   } else {
    //     props.onChange(event);
    //   }
    // }
  };
  //endregion Handlers

  return (
    <>
      {props.visualStyle === "simple" && (
        <SimpleDropdown
          options={buildings}
          componentLabel={componentLabel}
          defaultValue={buildingValue}
          onChange={handleChange}
        />
      )}

      {props.visualStyle === "old" && (
        <FabekeDropdown
          options={buildings}
          componentLabel={componentLabel}
          defaultValue={buildingValue}
          onChange={handleChange}
        />
      )}
    </>
  );
};

BuildingDropdownCommon.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  currentTeam: PropTypes.string,
  showAll: PropTypes.bool,
  onChange: PropTypes.func,
  visualStyle: PropTypes.oneOf(["simple", "old"]).isRequired
};