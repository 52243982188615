import axios from "axios";
import useSWR from "swr";
import {Cookies} from "react-cookie";
import {useApiResponse} from "../hooks";

const URI = require("uri-js");

const {REACT_APP_API_URL} = process.env;

const client = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 30000
});

/**
 * TODO: We need to add description here
 * @param {object} config In JSON format
 * @returns {string} With basic authentication string
 */
function getHeader(config) {
  const cookies = new Cookies();
  const token = cookies.get("userAuthToken");

  const urlList = ["/user/login", "/user/register", "/user/forget_password"];
  const currentURL = URI.parse(config.url).path;
  const basicAuthCredentials = btoa("globalgoals:goglobal!");
  let returnString = "";
  if (urlList.includes(currentURL)) {
    returnString = `Basic ${basicAuthCredentials}`;
  } else if (token) {
    returnString = `Bearer ${token}`;
  }
  return returnString;
}

client.interceptors.request.use(function (config) {
  config.headers.Authorization = getHeader(config);
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      // const cookies = new Cookies();
      // cookies.remove("userAuthToken");
    }
    return error;
  }
);

export default client;

/**
 * TODO: We need to add description here
 * @param {string} url URL
 * @param {object} params In JSON format
 * @returns {string} Cache key
 */
export function getCacheKey(url, params) {
  const cacheKey = [url];
  if (params) {
    cacheKey.push(JSON.stringify(params));
  }
  return cacheKey;
}

/**
 * TODO: We need to add description here
 * @param {string} url URL
 * @param {object} params In JSON format
 * @param {object} config In JSON format
 * @returns {object} In JSON format
 */
export function useAPIGraph(url, params, config) {
  const {
    data,
    mutate
  } = useSWR(
    getCacheKey(url, params),
    async () => {
      const {data: output} = await client.get(url, {
        params, ...config
      });
      output.isLoading = false;
      return output;
    },
    {
      // revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || {
      isLoading: true
    },
    mutate
  };
}

/**
 * TODO: We need to add description here
 * @param {string} url URL
 * @param {object} params In JSON format
 * @param {object} config In JSON format
 * @returns {object} In JSON format
 */
export function useAPIGraphPost(url, params, config) {
  const {
    data,
    mutate
  } = useSWR(
    getCacheKey(url, params),
    async () => {
      const {data: output} = await client.post(url, {
        addresses: params.addresses,
        customer: params.customer,
        from: params.from,
        resolution: params.resolution,
        to: params.to,
        type: params.type,
        unit: params.unit
      });
      output.isLoading = false;
      return output;
    },
    {
      // revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || {
      isLoading: true
    },
    mutate
  };
}

/**
 * TODO: We need to add description here
 * @param {string} url URL
 * @param {object} params In JSON format
 * @param {object} config in JSON format
 * @returns {object} In JSON format
 */
export function useAPI(url, params, config) {
  const {
    data,
    mutate
  } = useSWR(getCacheKey(url, params), async () => {
    const {data: output} = await client.get(url, {
      params, ...config
    });
    output.isLoading = false;
    return output;
  });

  const cachedResponse = useApiResponse(data);
  return {
    data: cachedResponse || {
      isLoading: true
    },
    mutate
  };
}
