import {formatDateDayMonthYearTime, toLocaleNumber} from "../";

export const energyMonthlyColumns = (data, gettext, locale) => {
  const columns = [];
  const consumptionData = data?.consumptionEnergyData?.consumptionData;
  if (Array.isArray(consumptionData) && consumptionData.length > 0 && consumptionData[0]?.details?.data) {
    columns.push(dateColumn(gettext));
    columns.push(numberColumn("sum", gettext("Sum"), locale, 150));
    const dataForColumns = data.consumptionEnergyData.consumptionData[0].details.data;
    dataForColumns.map((obj) => {
      columns.push(numberColumn(obj.meter, obj.meter, locale));
      return null;
    });
  }
  return columns;
};

const dateColumn = (gettext) => {
  return {
    field: "ts",
    headerName: gettext("Installation Numbers"),
    // flex: 1,
    type: "date",
    width: 150,
    valueGetter: (value) => {
      let returnValue = "";
      if (value) {
        if (value === "summary") {
          returnValue = gettext("Period/Sum");
        } else {
          // returnValue = value;
          returnValue = new Date(value);
        }
      }
      return returnValue;
    },
    valueFormatter: (value) => {
      let returnValue = "";
      if (value) {
        if (Object.prototype.toString.call(value) === '[object Date]') {
          // returnValue = value;
          returnValue = formatDateDayMonthYearTime(value);
        } else {
          returnValue = value;
        }
      }
      return returnValue;
    }
  };
};

const numberColumn = (field, headerName, locale, width = 200) => {
  if (field === "sum") {

  }
  return {
    field,
    headerName,
    width,
    type: "number",
    valueFormatter: (value) => toLocaleNumber(locale, value, 2),
    cellClassName: field === "sum" ? 'bold' : ""
  };
};