import {useQuery} from "@apollo/client";
import {GET_COMPANIES_BUILDINGS} from "./commands";

/**
 *
 * @param customerNamesString
 * @param customerSubsidiaryString
 * @param onCompleted
 * @param onError
 */
export function useCompanyBuildings(
  customerNamesString,
  customerSubsidiaryString,
  onCompleted = () => {
  },
  onError = () => {
  }
) {
  const {
    data,
    loading
  } = useQuery(GET_COMPANIES_BUILDINGS, {
    variables: {
      customerName: customerNamesString,
      subsidiaryName: customerSubsidiaryString
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    onCompleted: ({comCustomerAddresses}) => {
      onCompleted(comCustomerAddresses || []);
    },
    onError
  });

  return {
    buildings: data?.getBuildings || [],
    loading
  };
}
